import { useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import FormattingEditor from "../FormattingEditor";

const AddExperienceForm = ({
  toggleEditingExperience,
  addExperience,
  currentExperience,
  yearsExperience
}) => {
  const [formData, setFormData] = useState({
    years: currentExperience?.years || yearsExperience,
    licenses: currentExperience?.licenses || "",
    procedures: currentExperience?.procedures || ""
  });

  const handleEditorChange = (e) => {
    const sanitizedHtml = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: ["b", "i", "em", "strong", "ul", "ol", "li", "br", "p"]
    });
    setFormData((prevData) => ({
      ...prevData,
      procedures: sanitizedHtml
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addExperience(formData);
    toggleEditingExperience();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="years" className="mb-2 public-sans-regular">
          How many years of experience do you have?
        </label>
        <input
          type="text"
          className="form-control"
          id="years"
          name="years"
          placeholder="Years of Experience"
          onChange={handleChange}
          value={formData.years}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="licenses" className="mb-2 public-sans-regular">
          What licenses do you hold?
        </label>
        <input
          type="text"
          className="form-control"
          id="licenses"
          name="licenses"
          placeholder="Licenses"
          onChange={handleChange}
          value={formData.licenses}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="procedures" className="mb-2 public-sans-regular">
          What and how many procedures have you completed?
        </label>
        <FormattingEditor
          value={formData.procedures}
          onChange={handleEditorChange}
          name="procedures"
          id="procedures"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <Link onClick={toggleEditingExperience} className="text-decoration-none">
          Nevermind
        </Link>

        <button className="button-pill" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default AddExperienceForm;
