import { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useAuth } from "./AuthContext";
import useUserData from "../hooks/useUserData";
import {
  fetchCompanyData,
  fetchSubscriptionStatus,
  fetchPublishedJobs
} from "../services/CompanyService";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../firebase/config";

const CompanyContext = createContext();

export const useCompany = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const { userData, loading: userLoading } = useUserData(currentUser?.uid);

  const [state, setState] = useState({
    companyId: null,
    companyData: null,
    isOnboardingComplete: false,
    hasActiveSubscription: false,
    isTrialing: false,
    subEndDate: null,
    hasPublishedJobs: false,
    loading: true,
    error: null
  });

  const targetOnboardingArray = [1, 2, 3, 4, 5, 6];

  // Fetch company details
  useEffect(() => {
    // Check if still loading user data
    if (userLoading) {
      //console.log("waiting on userLoading to complete ...");
      return; //do nothing until user loading is complete
    }

    // If no userData or no companyId in userData, handle it
    if (!userData?.companyId) {
      setState((prev) => ({ ...prev, companyId: null, companyData: null, loading: false }));
      return;
    }

    // Update state with companyId and set loading to true
    const companyId = userData.companyId;
    setState((prev) => ({ ...prev, companyId, loading: true }));

    //Fetch company data once companyId is set
    const companyDocRef = doc(db, "companies", userData.companyId);
    const unsubscribe = onSnapshot(
      companyDocRef,
      async (companyDoc) => {
        if (companyDoc.exists()) {
          const companyData = companyDoc.data();
          const isOnboardingComplete = targetOnboardingArray.every((value) =>
            companyData.onboardingCompleted?.includes(value)
          );

          const [subscriptionStatus, hasPublishedJobs] = await Promise.all([
            fetchSubscriptionStatus(currentUser.uid),
            fetchPublishedJobs(companyId)
          ]);

          setState((prev) => ({
            ...prev,
            companyData,
            isOnboardingComplete,
            hasActiveSubscription: subscriptionStatus.active,
            isTrialing: subscriptionStatus.trialing,
            subEndDate: subscriptionStatus.endDate,
            hasPublishedJobs,
            loading: false
          }));
        } else {
          setState((prev) => ({ ...prev, error: "Company not found", loading: false }));
        }
      },
      (error) => {
        console.error("Error fetching company data:", error);
        setState((prev) => ({ ...prev, error: "Failed to load company data", loading: false }));
      }
    );

    return () => unsubscribe();
  }, [userLoading, userData, currentUser]);

  return (
    <CompanyContext.Provider value={{ ...state, currentUser, userData }}>
      {children}
    </CompanyContext.Provider>
  );
};
