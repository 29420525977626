import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useCompany } from "../context/CompanyContext";
import "./Connections.css";

const ConnectionItem = ({
  connectionId,
  companyName,
  status,
  date,
  profTitle,
  name,
  firstName,
  isReceived,
  onStatusChange,
  candidateId,
  companyId,
  initiator
}) => {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { userData } = useCompany();

  const handleConnectionResponse = async (choice) => {
    setLoading(true);
    try {
      const newStatus = choice === "accept" ? "accepted" : "rejected";
      await onStatusChange(connectionId, newStatus);
      //alert(`Connection with connectionId: ${connectionId} changed to ${newStatus} successfully.`);
    } catch (error) {
      console.error("Error updating connection:", error);
      //alert("Failed to update the connection. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Display connection items for CANDIDATE
  if (!userData.companyId) {
    return (
      <div key={connectionId} className="card connection-card">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="connection-card-header d-flex flex-column">
            <Link to={`/companies/${companyId}`}>
              <span className="connection-company">{companyName}</span>
            </Link>
            <span>{date}</span>
          </div>
          {isReceived && status === "pending" && (
            <div className="d-flex gap-3">
              <Link onClick={() => handleConnectionResponse("reject")}>Reject</Link>
              <Link onClick={() => handleConnectionResponse("accept")}>Accept</Link>
            </div>
          )}
          {isReceived && status === "accepted" && (
            <Link to={`/companies/${companyId}`}>View Profile</Link>
          )}
          {isReceived && status === "rejected" && (
            <span className="connection-status">{status}</span>
          )}
        </div>
      </div>
    );
  }

  if (userData.companyId) {
    return (
      <div key={connectionId} className="card connection-card">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="connection-card-header d-flex flex-column">
            {/* Display Candidate name/profTitle OR Company Name*/}
            {status === "pending" && <span className="connection-company">{profTitle}</span>}
            {status === "accepted" && <span className="connection-company">{name}</span>}
            <span>{date}</span>
          </div>
          {isReceived && status === "pending" && (
            <div className="d-flex gap-3">
              <Link onClick={() => handleConnectionResponse("reject")}>Reject</Link>
              <Link onClick={() => handleConnectionResponse("accept")}>Accept</Link>
            </div>
          )}

          {isReceived && status === "accepted" && (
            <Link to={`/candidates/${candidateId}`} target="_blank">
              View Profile
            </Link>
          )}

          {isReceived && status === "rejected" && (
            <span className="connection-status">{status}</span>
          )}
        </div>
      </div>
    );
  }
};

export default ConnectionItem;
