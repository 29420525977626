import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, collection, setDoc } from "firebase/firestore";
import OnboardingForm from "../components/OnboardingForm";
import { db } from "../firebase/config";
import { useAuth } from "../context/AuthContext";
import NPIForm from "../components/candidate/NPIForm";
import CandidateOnboardingProfileForm from "../components/candidate/CandidateOnboardingProfileForm";
import { logEvent, analytics } from "../firebase/config";
import "./Onboarding.css";

const Onboarding = () => {
  const [loading, setLoading] = useState(false);
  const [profilePrefillData, setProfilePrefillData] = useState();
  const [skipNumber, setSkipNumber] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const questions = [
    { id: 1, value: "firstName", text: "What's your first name?" },
    {
      id: 2,
      value: "profTitle",
      text: "What's the professional title you go by?"
    },
    {
      id: 3,
      value: "location",
      text: "What city and state do you currently live in?"
    },
    { id: 4, value: "yearsExperience", text: "How many years have you been practicing for?" },
    {
      id: 5,
      value: "npiNumber",
      text: "What's your NPI number?",
      helpText: "NPI numbers are not shared with companies or other dentists.",
      checkbox: "I'm a student or don't have an NPI number.",
      skip: true,
      placeholder: "Optional"
    }
  ];

  const submitForm = async (answers) => {
    const userDocRef = doc(db, "users", currentUser.uid);

    try {
      // Update firestore with user's answers
      await updateDoc(userDocRef, answers);

      // Check if the NPI number is provided
      if (answers.npiNumber) {
        const apiUrl = `https://us-central1-medis-8c15b.cloudfunctions.net/npiProxy?number=${answers.npiNumber}`;

        // Fetch data from the NPI registry API
        const response = await fetch(apiUrl);
        console.log("Response from server is:", response);
        const data = await response.json();
        console.log("Data is:", data);

        // If results are founds, store them in Firestore in a new collection in the user document
        console.log(data.results_count > 0);
        if (data.results_count !== "0") {
          const validatedDataRef = doc(collection(userDocRef, "validated_data"));
          await setDoc(validatedDataRef, { results: data.results });
        }
      }

      // Navigate to the candidate profile page
      navigate("/candidate/profile");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Function to transform uppercase data from NPI into normal title case
  const toTitleCase = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  // A helper function to transform enumeration data from NPI to number of years in practice
  const calculateYears = (dateString) => {
    if (!dateString) return null; // Handle empty or undefined strings

    const providedDate = new Date(dateString);
    const today = new Date();

    // Calculate the difference in years
    let yearsDifference = today.getFullYear() - providedDate.getFullYear();

    // Adjust for cases where the current year's month/day hasn't yet reached the provided date's month/day
    const monthDifference = today.getMonth() - providedDate.getMonth();
    const dayDifference = today.getDate() - providedDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      yearsDifference--;
    }

    return yearsDifference;
  };

  const submitNpiNumber = async (number) => {
    setLoading(true);
    //console.log(number);

    // Define the user object to which we'll save profile data
    const userDocRef = doc(db, "users", currentUser.uid);

    try {
      // Check if  NPI number is 10 digits long
      if (number.length == 10) {
        const apiUrl = `https://us-central1-medis-8c15b.cloudfunctions.net/npiProxy?number=${number}`;

        // Fetch data from the NPI registry API
        const response = await fetch(apiUrl);
        console.log("Response from server is:", response);
        const data = await response.json();
        console.log("Data is:", data);

        if (data.results.length > 0) {
          // Update Profile Prefill state to use validated NPI data to prefill profile form
          setProfilePrefillData({
            name: `${toTitleCase(data.results[0].basic.first_name)} ${toTitleCase(data.results[0].basic.last_name)}`,
            location: `${toTitleCase(data.results[0].addresses[0].city)} ${data.results[0].addresses[0].state}`,
            specialties: data.results[0].taxonomies[0].desc,
            profTitle: data.results[0].basic.credential,
            yearsPracticing: calculateYears(data.results[0].basic.enumeration_date)
          });

          // Post data to Firebase in a new object called validated_data

          const validatedDataRef = doc(collection(userDocRef, "validated_data"));
          await setDoc(validatedDataRef, { results: data.results });
          logEvent(analytics, "npi_submitted", { userType: "candidate" });
        } else {
          setLoading(false);
          alert("No results were found for this NPI number");
          logEvent(analytics, "npi_not_found", { userType: "candidate" });
        }
      } else {
        setLoading(false);
        alert("NPI must be a 10-digit number");
        logEvent(analytics, "npi_invalid", { userType: "candidate" });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      logEvent(analytics, "npi_error", { userType: "candidate" });
    } finally {
      setLoading(false);
    }
  };

  const skipNpiNumber = () => {
    setSkipNumber(true);
    logEvent(analytics, "npi_skipped", { userType: "candidate" });
  };

  const handleProfileSubmit = async (formData) => {
    //console.log(formData);
    const userDocRef = doc(db, "users", currentUser.uid);

    setLoading(true);
    try {
      await setDoc(userDocRef, formData, { merge: true });

      // Track which fields were filled
      const submittedFields = Object.keys(formData).filter(
        (key) => formData[key] && formData[key].trim() !== ""
      );

      // Log event with Google Analytics
      logEvent(analytics, "onboarding_profile_complete", {
        userId: currentUser.uid,
        submittedFields: submittedFields.join(", "), // Convert to comma-separated string
        totalFieldsCompleted: submittedFields.length,
        userType: "candidate"
      });

      setLoading(false);
      navigate("/candidate/profile");
    } catch (error) {
      setLoading(false);
      console.error("Issue saving user data to profile:", error);
    }
  };

  return (
    <>
      <section>
        <div className="text-center">
          <h3 className="text-center fw-bold">Let's Build Your Profile</h3>
          <p className="fw-lighter">
            Great job getting yourself out there! Let's show dental offices what you're all about.
          </p>
        </div>
        {!skipNumber && (
          <div className="card my-0 mx-auto onboarding-card">
            <div className="card-body">
              <NPIForm
                submitNpiNumber={submitNpiNumber}
                loading={loading}
                data={profilePrefillData}
                skipNpiNumber={skipNpiNumber}
              />
            </div>
          </div>
        )}

        {(profilePrefillData || skipNumber) && (
          <div className="card mt-2 mx-auto onboarding-card">
            <div className="card-body">
              <CandidateOnboardingProfileForm
                data={profilePrefillData}
                onSubmit={handleProfileSubmit}
                loading={loading}
                skipNumber={skipNumber}
              />
            </div>
          </div>
        )}
      </section>

      {/* <OnboardingForm questions={questions} onSubmit={submitForm} /> */}
    </>
  );
};

export default Onboarding;
