import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserData from "./hooks/useUserData";

const AuthRedirect = () => {
  const { userData, loading } = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && userData) {
      if (userData.companyId) {
        navigate("/company/profile", { replace: true });
      } else {
        navigate("/candidate/profile", { replace: true });
      }
    }
  }, [loading, userData, navigate]);
  return null;
};

export default AuthRedirect;
