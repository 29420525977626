import { Outlet } from "react-router-dom";
// import { useAuth } from "../context/AuthContext";
import { useCompany } from "../context/CompanyContext";

//Components
import Navbar from "./Navbar";
import CompanyNavbar from "./CompanyNavbar";
import LoadingSpinner from "./LoadingSpinner";

//Styles
import "../App.css";
import "./Layout.css";

const Layout = () => {
  const { companyId, loading, error, isOnboardingComplete } = useCompany();

  // Redirect to home if no user is logged in
  // if (!currentUser) {
  //   navigate("/");
  // }

  // Show loading spinner while data is loading
  if (loading) {
    return <LoadingSpinner />;
  }

  // Display error message if there's an error
  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="app-layout">
      {/* conditionally render navbar */}
      {companyId ? <CompanyNavbar isOnboardingComplete={isOnboardingComplete} /> : <Navbar />}
      <main className="content">
        <Outlet /> {/* This should render nested routes */}
      </main>
    </div>
  );
};

export default Layout;
