import { useState } from "react";

const OnboardingDescription = ({ companyData, onboardingProgress, saveCompanyDescription }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [description, setDescription] = useState("");

  console.log(companyData);

  // Check if company description  step is complete
  const isDescriptionComplete =
    companyData?.onboardingCompleted?.includes(4) || onboardingProgress.includes(4);

  const toggleFormOpen = () => {
    setFormOpen((prevState) => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCompanyDescription(description);
    toggleFormOpen();
  };

  return (
    <div className={`card mb-3 ${isDescriptionComplete && "theme-dark"}`}>
      <div className="d-flex justify-content-between align-items-center py-2 px-3">
        <div className="d-flex justify-content-start align-items-center gap-3">
          <i className="bi-pen" style={{ fontSize: "2rem" }}></i>
          <div>
            <h6 className="mb-0">Describe your company</h6>
            <span className="small fw-lighter">Tell candidates about the real you</span>
          </div>
        </div>
        {isDescriptionComplete && (
          <span className="">
            <i className="bi-check-lg" />
          </span>
        )}
        {!isDescriptionComplete && !formOpen && (
          <button className="button-pill small" onClick={toggleFormOpen}>
            Add description
          </button>
        )}
        {!isDescriptionComplete && formOpen && (
          <i className="bi-x-circle" onClick={toggleFormOpen}></i>
        )}
      </div>
      {formOpen && (
        <form onSubmit={handleSubmit} className="p-3">
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe your company"
              style={{ height: "100px" }}
            />
            <label htmlFor="description" className="small">
              Short Description
            </label>
          </div>
          <button className="button-pill small" type="submit">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default OnboardingDescription;
