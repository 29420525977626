import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useCompany } from "../../context/CompanyContext";
import LoadingSpinner from "../LoadingSpinner";

const CompanyProfileForm = ({
  companyData,
  companyId,
  toggleEditingProfile,
  toggleSuccessAlert
}) => {
  const [loading, setLoading] = useState(false);

  // Form State
  const [formData, setFormData] = useState({
    description: ""
  });

  // Prefill data when companyData is available
  // Should default include a name as that is required for registration

  useEffect(() => {
    if (companyData) {
      try {
        setLoading(true);
        setFormData({
          description: companyData.description || ""
        });
      } catch (error) {
        console.error("error fetching company information:", error.message);
        throw error;
      } finally {
        setLoading(false);
      }
    }
  }, [companyData]);

  // handle changes user makes to form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    console.log(formData);
  };

  // Handle a form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const companyDocRef = doc(db, "companies", companyId);
    try {
      await updateDoc(companyDocRef, {
        description: formData.description
      });
      setLoading(false);
      console.log("Company data updated successfull");
    } catch (error) {
      console.error("error udpating company data:", error);
      setLoading(false);
    } finally {
      // toggleSuccessAlert();
      toggleEditingProfile();
    }
  };

  return (
    <div className="company-profile-section">
      {loading && <LoadingSpinner />}

      <div className="card p-4 form-card" id="profile">
        <h4 className="mb-4">Edit company description</h4>
        <form className="dentist-office-form" onSubmit={handleSubmit}>
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Short Description"
              style={{ height: "100px" }}
              disabled={loading}
            />
            <label htmlFor="description">Short Description</label>
          </div>
          <div className="d-flex align-items-center gap-3">
            {!loading ? (
              <button type="submit" className="button-large public-sans-regular">
                Save
              </button>
            ) : (
              <button className="button-large public-sans-regular" disabled>
                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                <span role="status">Loading...</span>
              </button>
            )}
            <Link onClick={toggleEditingProfile} className="text-decoration-none">
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyProfileForm;
