// Libraries and Context
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { doc, collection, updateDoc, addDoc, arrayUnion } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from "../../firebase/config";
import { Link } from "react-router-dom";
import { useCompany } from "../../context/CompanyContext";

// Components
import LoadingSpinner from "../LoadingSpinner";
import CompanyProfileForm from "./CompanyProfileForm";
import CompanyDescriptionSection from "./CompanyDescriptionSection";
import CompanyLocationsSection from "./CompanyLocationsSection";
import AboutCompanySection from "./AboutCompanySection";
import OfficePhotos from "./OfficePhotos";
import Card from "../Card";

// Images
import profilePic from "/user-pic.png";
import CompanyOnboarding from "./CompanyOnboarding";
import CompanyManageUser from "./CompanyManageUser";

const CompanyProfile = () => {
  const {
    currentUser,
    companyId,
    companyData,
    userData,
    aboutOurCompany,
    hasActiveSubscription,
    isTrialing,
    subEndDate,
    loading,
    fetchCompanyData,
    isOnboardingComplete
  } = useCompany();

  const location = useLocation();

  // Local state
  const [editingProfile, setEditingProfile] = useState(false);
  const [displayAboutForm, setDisplayAboutForm] = useState(false);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [alertFading, setAlertFading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // State for Onboarding Progress - MAY NOT NEED
  const [onboardingProgress, setOnboardingProgress] = useState(
    () => companyData?.onboardingCompleted || [1]
  );

  const [aboutSection, setAboutSection] = useState({
    sectionTitle: "",
    sectionBody: ""
  });

  console.log("companyData:", companyData);
  console.log("currentUser:", currentUser);
  console.log("companyid:", companyId);
  console.log("hasActiveSubscription:", hasActiveSubscription);
  console.log("subEndDate:", subEndDate);
  console.log("isTrialing:", isTrialing);

  // **Update Onboarding Progress**
  const updateOnboadringProgress = async (step) => {
    if (!companyId) return;

    // First, update firebase to store progress in company record
    const companyDocRef = doc(db, "companies", companyId);
    await updateDoc(companyDocRef, {
      onboardingCompleted: arrayUnion(step)
    });

    // Fetch updated company data
    await fetchCompanyData();
  };

  // ** Upload Logo **
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2mb file size

  // Handle file change and check for size
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      alert("File is too large! Please upload a file smaller than 2MB.");
      return;
    }
    uploadFile(file);
  };

  // Upload file to storage bucket
  const uploadFile = async (file) => {
    const storageRef = ref(storage, `company_logos/${companyId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(uploadProgress);
      },
      (error) => {
        console.error("Upload failed: ", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await updateDoc(doc(db, "companies", companyId), {
          logo: downloadURL,
          onboardingCompleted: arrayUnion(3)
        });
        setUploading(false);
        await fetchCompanyData();
      }
    );
  };

  // ** Update Description **

  const saveCompanyDescription = async (description) => {
    await updateDoc(doc(db, "companies", companyId), {
      description,
      onboardingCompleted: arrayUnion(4)
    });
    await fetchCompanyData();
  };

  // ** Update Website **
  const saveCompanyWebsite = async (website) => {
    await updateDoc(doc(db, "companies", companyId), {
      website,
      onboardingCompleted: arrayUnion(5)
    });
    await fetchCompanyData();
  };

  // **Add Location**
  const saveLocation = async (newLocation) => {
    const locationsRef = collection(db, "companies", companyId, "locations");
    await addDoc(locationsRef, newLocation);
    await updateDoc(doc(db, "companies", companyId), {
      onboardingCompleted: arrayUnion(6)
    });
    await fetchCompanyData();
  };

  // **Handle Onboarding Subscription**
  useEffect(() => {
    const handleOnboardingSub = async () => {
      const params = new URLSearchParams(location.search);
      const paymentStatus = params.get("payment_status");
      const sessionId = params.get("session_id");

      if (paymentStatus === "success" && sessionId) {
        await updateDoc(doc(db, "companies", companyId), {
          onboardingCompleted: arrayUnion(2)
        });
        await fetchCompanyData();
      }
    };

    handleOnboardingSub();
  }, [location.search, companyId]);

  // **Handle UI Updates**
  const toggleEditingProfile = () => setEditingProfile((prev) => !prev);

  const toggleSuccessAlert = () => {
    setSuccessAlertVisible((prevSuccessAlertVisible) => !prevSuccessAlertVisible);
  };

  if (successAlertVisible === true) {
    setTimeout(() => {
      setAlertFading(true);
      setTimeout(() => {
        setSuccessAlertVisible(false);
        setAlertFading(false);
      }, 500);
    }, 2000);
  }

  const handleAddSection = async (newSection) => {
    try {
      await updateDoc(doc(db, "companies", companyId), {
        aboutOurCompany: arrayUnion(newSection)
      });
    } catch (error) {
      console.error("Error adding new section: ", error);
    } finally {
      setDisplayAboutForm(false);
    }
  };

  const toggleDisplayAboutForm = () => {
    setDisplayAboutForm((prevDisplayAboutForm) => !prevDisplayAboutForm);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!companyData) {
    return <div>Company data couldn't load, try again.</div>;
  }

  if (isOnboardingComplete === undefined) {
    <div>
      This company has legacy data that will not work with recent updates. Please create a new
      company account.
    </div>;
  }

  return (
    <>
      {!isOnboardingComplete && (
        <CompanyOnboarding
          currentUser={currentUser}
          companyData={companyData}
          hasActiveSubscription={hasActiveSubscription}
          handleFileChange={handleFileChange}
          uploading={uploading}
          onboardingProgress={onboardingProgress}
          saveCompanyDescription={saveCompanyDescription}
          saveCompanyWebsite={saveCompanyWebsite}
          saveLocation={saveLocation}
        />
      )}

      {isOnboardingComplete && (
        <section className="profile public-sans-regular">
          {hasActiveSubscription && (
            <>
              {successAlertVisible && (
                <div
                  className={`alert alert-dismissible ${alertFading ? "fade-out" : ""}`}
                  role="alert">
                  Your profile was successfully updated.
                  <button
                    className="btn-close btn-close-white"
                    type="button"
                    aria-label="close"
                    onClick={toggleSuccessAlert}></button>
                </div>
              )}
              {!loading && !editingProfile && (
                <>
                  <CompanyDescriptionSection
                    profilePic={profilePic}
                    name={companyData.name}
                    description={companyData.description}
                    website={companyData.website}
                    phone={companyData.phone}
                    email={companyData.email}
                    toggleEditingProfile={toggleEditingProfile}
                  />
                  <CompanyLocationsSection companyId={companyId} companyData={companyData} />
                  <AboutCompanySection
                    aboutData={aboutOurCompany}
                    handleAddSection={handleAddSection}
                  />

                  <OfficePhotos />

                  {/* To Do - Update this card to add UI that shows whether user is in trial mode and sub end date */}
                  <Card>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <h4>Manage Subscription</h4>
                      <Link
                        target="_blank"
                        to={`https://billing.stripe.com/p/login/test_fZe6rl0BHft11oc4gg?prefilled_email=${userData.email}`}>
                        <button className="button-pill">Manage Subscription</button>
                      </Link>
                    </div>
                    <div>
                      {subEndDate
                        ? isTrialing
                          ? `Your trial expires on ${new Intl.DateTimeFormat("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric"
                            }).format(subEndDate.toDate())}`
                          : `Your subscription renews on ${new Intl.DateTimeFormat("en-US", {
                              month: "short",
                              day: "numeric",
                              year: "numeric"
                            }).format(subEndDate.toDate())}`
                        : "No subscription data available"}
                    </div>
                  </Card>
                  <Card>
                    <CompanyManageUser currentUser={currentUser} />
                  </Card>
                </>
              )}

              {editingProfile && (
                <CompanyProfileForm
                  companyData={companyData}
                  companyId={companyId}
                  toggleEditingProfile={toggleEditingProfile}
                  toggleSuccessAlert={toggleSuccessAlert}
                />
              )}
            </>
          )}
        </section>
      )}
    </>
  );
};

export default CompanyProfile;
