import { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../context/AuthContext";
import { getErrorMessage } from "../utils/authErrors";
import { analytics, logEvent } from "../firebase/config";
import Modal from "./Modal";

const LoginForm = ({ onClick }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [displayErrorModal, setDisplayErrorModal] = useState(false);
  const { setCurrentUser } = useAuth();

  const logInSubmission = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      // Use firebase to sign the user in
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Update the auth context with the current user
      setCurrentUser(user);
      logEvent(analytics, "login_complete", { method: "email" });
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage);
      setDisplayErrorModal(true);
      logEvent(analytics, "login_error", { error: error.message });
      console.error("error logging in:", error.code, error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {displayErrorModal && (
        <Modal title="Error logging in" onClose={() => setDisplayErrorModal(false)}>
          {error}
        </Modal>
      )}
      <form onSubmit={logInSubmission} className="login-form">
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <label htmlFor="email" className="public-sans-regular">
            Email address
          </label>
        </div>
        <div className="form-floating mb-2">
          <input
            type="password"
            className="form-control public-sans-regular"
            id="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <label htmlFor="password" className="public-sans-regular">
            Password
          </label>
        </div>
        <Link className="small text-decoration-none" onClick={onClick}>
          Forgot password?
        </Link>
        {loading ? (
          <button className="button-large w-100" disabled>
            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
            <span role="status">Loading...</span>
          </button>
        ) : (
          <button className="button-large w-100 public-sans-regular" type="submit">
            Log In
          </button>
        )}
      </form>
    </>
  );
};

export default LoginForm;
