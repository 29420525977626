/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback } from "react";
import { createConnection, fetchConnection } from "../../services/ConnectionService";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";

const AllCandidatesList = ({ candidates, hasActiveJobs, companyId, onNewConnection }) => {
  const [loading, setLoading] = useState({});
  const [connections, setConnections] = useState({});
  const [isLoadingConnections, setIsLoadingConnections] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Extract unique locations from candidates
  const uniqueLocations = [...new Set(candidates.map((c) => c.location))].filter(Boolean);

  const fetchAllConnections = useCallback(async () => {
    if (!companyId || candidates.length === 0) {
      setIsLoadingConnections(false);
      return;
    }

    try {
      // create a query to get all connections for the current company
      const connectionsRef = collection(db, "connections");
      const connectionsQuery = query(connectionsRef, where("companyId", "==", companyId));
      const connectionsSnapshot = await getDocs(connectionsQuery);

      // Process connection data
      const connectionMap = {};
      connectionsSnapshot.forEach((doc) => {
        const data = doc.data();
        connectionMap[data.candidateId] = data.status;
      });

      setConnections(connectionMap);
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setIsLoadingConnections(false);
    }
  }, [candidates, companyId]);

  useEffect(() => {
    fetchAllConnections();
  }, [fetchAllConnections]);

  /**
   * Handle connection request
   */

  const handleConnect = async (candidateId) => {
    setLoading((prev) => ({ ...prev, [candidateId]: true }));
    try {
      const response = await createConnection(candidateId, companyId, "company");
      if (response.success) {
        setConnections((prev) => ({ ...prev, [candidateId]: "pending" }));
        console.log("Connection request sent successfully:", response);
        onNewConnection(response);
      }
    } catch (error) {
      console.error("Error creating connection:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [candidateId]: false }));
    }
  };

  // filter candidates based on selected location
  const filteredCandidates = selectedLocation
    ? candidates.filter((c) => c.location === selectedLocation)
    : candidates;

  return (
    <>
      {/* Filter menu */}
      <div className="mb-3">
        <div className="dropdown">
          <button
            className="button-pill dropdown-toggle"
            type="button"
            onClick={() => setDropdownOpen(!dropdownOpen)}>
            {selectedLocation ? `Location: ${selectedLocation}` : "Filter by Location"}
          </button>
          {dropdownOpen && (
            <ul className="dropdown-menu show">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setSelectedLocation(null);
                    setDropdownOpen(false);
                  }}>
                  All Locations
                </button>
              </li>
              {uniqueLocations.map((location) => (
                <li key={location}>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedLocation(location);
                      setDropdownOpen(false);
                    }}>
                    {location}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {isLoadingConnections ? (
        <>
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title placeholder-glow mb-3">
                <span className="placeholder col-2"></span>
              </h5>
              <p className="card-text placeholder-glow d-flex flex-column gap-1">
                <span className="placeholder col-5"></span>
                <span className="col-3 placeholder"></span>
              </p>
            </div>
          </div>
          <div className="text-center my-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading candidates...</span>
            </div>
          </div>
        </>
      ) : (
        filteredCandidates.map((candidate) => (
          <div className="card mb-3" key={candidate.id}>
            <div className="card-body">
              <div className="d-flex align-item-center justify-content-between flex-wrap">
                {/* left  container */}
                <div>
                  {/* Card Heading */}
                  <h5 className="card-title">
                    {candidate.profTitle || candidate.profTitle != ""
                      ? candidate.profTitle
                      : "Candidate Name Hidden"}
                    {/* {hasActiveJobs ? candidate.firstName : candidate.profTitle} */}
                  </h5>

                  {/* Card Metadata */}
                  <div className="d-flex justify-content-start align-items-center gap-4 flex-wrap">
                    <span className="d-flex justify-content-start gap-2 align-items-center">
                      <i className="bi-geo-alt"></i>
                      <p className="card-text ">{candidate.location}</p>
                    </span>
                    {candidate.specialties != null && (
                      <span className="d-flex justify-content-start gap-2 align-items-center">
                        <i className="bi-award"></i>
                        <p className="card-text ">{candidate.specialties}</p>
                      </span>
                    )}
                  </div>
                </div>

                {/* right container */}
                <div className="d-flex jusify-content-between align-items-center">
                  {loading[candidate.id] ? (
                    <span>Loading...</span>
                  ) : connections[candidate.id] === "accepted" ? (
                    <span>Connected</span>
                  ) : connections[candidate.id] === "pending" ? (
                    <span>Request Sent</span>
                  ) : connections[candidate.id] === "rejected" ? (
                    <span></span>
                  ) : (
                    <div
                      className="d-flex gap-1 align-items-center"
                      onClick={() => handleConnect(candidate.id)}
                      style={{ cursor: "pointer" }}>
                      <>
                        <i className="bi-plus-lg"></i>
                        <span>Connect</span>
                      </>
                    </div>
                  )}
                </div>
              </div>

              {/* {hasActiveJobs ? (
              <Link to={`/candidates/${candidate.id}`}>View Full Profile</Link>
            ) : (
              <Link to="/company/jobs">To view candidate details, publish a job.</Link>
            )} */}
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default AllCandidatesList;
