import React from "react";
import { Link } from "react-router-dom";
import "./Profile.css";
import Navbar from "../components/Navbar";

const Profile = () => {
  return (
    <>
      <Navbar auth="logged-in" />
      <div className="container">
        <section className="profile-section d-flex flex-row py-3">
          <div className="sidebar d-flex flex-column py-3 px-4 bg-body-tertiary shadow me-4">
            <h2 className="public-sans-bold pt-2">Profile</h2>
            <hr />
            <ul className="list-unstyled ps-0">
              <li className="mb-1">
                <button
                  className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  Home
                </button>
                <div className="collapse" id="home-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <a
                        href="#"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        Profile
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="profile-menu d-flex flex-column mb-auto">
              <li className="profile-menu-item">
                <Link to="/" className="profile-menu-item-link active">
                  About Me
                </Link>
              </li>
              <li className="profile-menu-item">
                <Link className="profile-menu-item-link">Education</Link>
              </li>
              <li className="profile-menu-item">
                <Link className="profile-menu-item-link">Work History</Link>
              </li>
              <li className="profile-menu-item">
                <Link className="profile-menu-item-link">Employment Preferences</Link>
              </li>
            </ul>
          </div>
          <div className="content profile-section flex-grow-1 py-3 px-4 bg-body-tertiary shadow me-4">
            <form>
              {/* Profile Picture */}
              <div className="mb-3">
                <label htmlFor="profilePicture" className="form-label">
                  Profile Picture
                </label>
                <input className="form-control" type="file" id="profilePicture" />
              </div>

              {/* Full Name */}
              <div className="form-floating mb-3">
                <input type="text" className="form-control" id="fullName" placeholder="Full Name" />
                <label htmlFor="fullName">Full Name</label>
              </div>

              {/* Professional Title */}
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="professionalTitle"
                  placeholder="Professional Title"
                />
                <label htmlFor="professionalTitle">Professional Title</label>
              </div>

              {/* Email */}
              <div className="form-floating mb-3">
                <input type="email" className="form-control" id="email" placeholder="Email" />
                <label htmlFor="email">Email</label>
              </div>

              {/* Phone Number */}
              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Phone Number"
                />
                <label htmlFor="phoneNumber">Phone Number</label>
              </div>

              {/* Location (City, State) */}
              <div className="form-floating mb-3">
                <input type="text" className="form-control" id="location" placeholder="Location" />
                <label htmlFor="location">Location (City, State)</label>
              </div>

              {/* Summary/Bio */}
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a summary here"
                  id="summaryBio"
                  style={{ height: "150px" }}
                ></textarea>
                <label htmlFor="summaryBio">Summary/Bio</label>
              </div>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Profile;
