/* eslint-disable react/prop-types */
import { useState } from "react";
import Modal from "../Modal";
import { useAuth } from "../../context/AuthContext";
import { auth, db } from "../../firebase/config";
import { reauthenticateWithCredential, updatePassword, EmailAuthProvider } from "firebase/auth";

const CompanyManageUser = () => {
  const { currentUser } = useAuth();
  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleDisplayModal = () => {
    setDisplayPasswordModal((prevState) => !prevState);
    setError("");
    setSuccess("");
  };

  const handleChangeUserPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      if (!currentUser) {
        setError("No user is current logged in.");
        return;
      }
      // Create credentials using EmailAuthProvider

      const credential = EmailAuthProvider.credential(currentUser.email, password);

      // Reauthenticate the user before changing the password
      // https://firebase.google.com/docs/auth/web/manage-users?hl=en&authuser=0#re-authenticate_a_user

      await reauthenticateWithCredential(currentUser, credential);

      // Update the user's password
      await updatePassword(currentUser, newPassword);
      setSuccess("Password updated successfully!");
      setPassword("");
      setNewPassword("");
      setLoading(false);
      toggleDisplayModal(); // Close modal on success
    } catch (error) {
      console.error("Error changing password:", error);
      setError(error.message);
    }
  };

  return (
    <>
      {displayPasswordModal && (
        <Modal title="Change password" onClose={toggleDisplayModal}>
          <form>
            <div className="mb-3 form-floating">
              <input
                className="form-control"
                type="password"
                name="currentPassword"
                id="currentPassword"
                placeholder="Enter current password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
              <label htmlFor="currentPassword">Current Password</label>
            </div>
            <div className="mb-3 form-floating">
              <input
                className="form-control"
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="Enter new password"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                required
              />
              <label htmlFor="newPassword">New Password</label>
            </div>
            {error && <p className="text-danger">{error}</p>}
            {success && <p className="text-success">{success}</p>}
            {!loading ? (
              <button className="button-pill" onClick={handleChangeUserPassword}>
                Submit
              </button>
            ) : (
              <button className="button-pill" disabled>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"></span>
                Loading...
              </button>
            )}
          </form>
        </Modal>
      )}

      <div className="d-flex flex-row justify-content-between align-items-center">
        <h4>Change Password</h4>
        <button className="button-pill" onClick={toggleDisplayModal}>
          Change Password
        </button>
      </div>
    </>
  );
};

export default CompanyManageUser;
