export const getJobStatus = (status) => {
  switch (status) {
    case "actively-seeking":
      return "Actively Seeking";
    case "open-to-opportunities":
      return "Open to Opportunities";
    case "not-actively-seeking":
      return "Not Actively Seeking";
  }
};
