import { useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import FormattingEditor from "../FormattingEditor";

const AddEducationForm = ({ toggleEditingEducation, addEducation, currentEducation = {} }) => {
  const [formData, setFormData] = useState({
    institution: currentEducation.institution || "",
    startYear: currentEducation.startYear || "",
    endYear: currentEducation.endYear || "",
    additionalContent: currentEducation.additionalContent || ""
  });

  const handleEditorChange = (e) => {
    const sanitizedHtml = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: ["b", "i", "em", "strong", "ul", "ol", "li", "br", "p"]
    });
    setFormData((prevData) => ({
      ...prevData,
      additionalContent: sanitizedHtml
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addEducation(formData);
    toggleEditingEducation();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="institution" className="mb-2 public-sans-regular">
          Institution Name
        </label>
        <input
          type="text"
          className="form-control"
          id="institution"
          name="institution"
          placeholder="Enter institution name"
          onChange={handleChange}
          value={formData.institution}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="startYear" className="mb-2 public-sans-regular">
          What year did you start?
        </label>
        <input
          type="text"
          className="form-control"
          id="startYear"
          name="startYear"
          placeholder="Enter start year"
          onChange={handleChange}
          value={formData.startYear}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="endYear" className="mb-2 public-sans-regular">
          What year did you complete?
        </label>
        <input
          type="text"
          className="form-control"
          id="endYear"
          name="endYear"
          placeholder="Enter completion year"
          onChange={handleChange}
          value={formData.endYear}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="additionalContent" className="mb-2 public-sans-regular">
          What would you like to tell us about this degree?
        </label>
        <FormattingEditor
          value={formData.additionalContent}
          onChange={handleEditorChange}
          name="additionalContent"
          id="additionalContent"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <Link onClick={toggleEditingEducation} className="text-decoration-none">
          Cancel
        </Link>
        <button className="button-pill" type="submit">
          Add Institution
        </button>
      </div>
    </form>
  );
};

export default AddEducationForm;
