// Universal service to fetch and create connections between companies and candidates
// Currently all logic is happening in ConnectionsContext.jsx

import { collection, query, where, getDocs, addDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";

// Fetch connections for a user
// this fetches the first connect where candidate and company match
// it will not work in contexts where we want to present all connections to a user
// independent of a job, or where multiple objects need to be returned
export const fetchConnection = async (candidateId, companyId) => {
  try {
    const connectionsRef = collection(db, "connections");
    const connectionQuery = query(
      connectionsRef,
      where("candidateId", "==", candidateId),
      where("companyId", "==", companyId)
    );
    const connectionSnapshot = await getDocs(connectionQuery);

    if (!connectionSnapshot.empty) {
      return connectionSnapshot.docs[0].data(); // Return the first matching connection
    }
    return null; // No connection found
  } catch (error) {
    console.error("Error fetching connection:", error);
    throw error;
  }
};

// Create a new connection
// will need to ensure job id is not required or force job id when a company connects
export const createConnection = async (candidateId, companyId, initiator) => {
  try {
    const connectionsRef = collection(db, "connections");

    // Fetch Company Name
    const companyDoc = await getDoc(doc(db, "companies", companyId));
    const companyName = companyDoc.exists() ? companyDoc.data().name : "Unknown company";

    // Fetch candidate details
    const candidateDoc = await getDoc(doc(db, "users", candidateId));
    const candidateData = candidateDoc.exists()
      ? candidateDoc.data()
      : { name: "Unknown Candidate", profTitle: "Unknown Title" };

    const connectionData = {
      candidateId,
      companyId,
      status: "pending",
      initiator: initiator,
      createdAt: new Date().toISOString(),
      companyName,
      profTitle: candidateData.profTitle || "Unknown candidate title",
      name: candidateData.name || "Unknown candidate name"
    };

    console.log("Connection Data:", connectionData);

    // Add the connection to Firestore
    const connectionDocRef = await addDoc(connectionsRef, connectionData);
    return { success: true, id: connectionDocRef.id, ...connectionData };
  } catch (error) {
    console.error("Error creating connection:", error);
    throw error;
  }
};

// Get all connections for a user (candidate or company)
export const getAllConnections = async (userId, isCompany = false) => {
  try {
    const connectionsRef = collection(db, "connections");
    const connectionsQuery = query(
      connectionsRef,
      where(isCompany ? "companyId" : "candidateId", "==", userId)
    );
    const connectionSnapshot = await getDocs(connectionsQuery);

    const connections = connectionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));

    // Separate the connections into different lists
    const pendingReceived = connections.filter(
      (connection) =>
        connection.status === "pending" &&
        connection.initiator !== (isCompany ? "company" : "candidate")
    );

    const pendingSent = connections.filter(
      (connection) =>
        connection.status === "pending" &&
        connection.initiator === (isCompany ? "company" : "candidate")
    );

    const accepted = connections.filter((connection) => connection.status === "accepted");

    return {
      pendingReceived,
      pendingSent,
      accepted
    };
  } catch (error) {
    console.error("Error fetching connections:", error);
    throw error;
  }
};
