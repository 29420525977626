import React from "react";

const OnboardingLogo = ({ handleFileChange, uploading, onboardingProgress, companyData }) => {
  const triggerFileInput = () => {
    document.getElementById("uploadFile").click();
  };

  // Check if logo upload step is complete
  const isLogoUploaded =
    companyData?.onboardingCompleted?.includes(3) || onboardingProgress.includes(3);

  return (
    <div className={`card mb-3 ${isLogoUploaded && "theme-dark"}`}>
      <div className="d-flex justify-content-between align-items-center py-2 px-3">
        <div className="d-flex justify-content-start align-items-center gap-3">
          <i className="bi-image" style={{ fontSize: "2rem" }}></i>
          <div>
            <h6 className="mb-0">Add your logo</h6>
            <span className="small fw-lighter">Show candidates your brand identity</span>
          </div>
        </div>
        <input
          type="file"
          id="uploadFile"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {!uploading && isLogoUploaded && (
          <span className="">
            <i className="bi-check-lg" />
          </span>
        )}

        {!uploading && !isLogoUploaded && (
          <button
            className="button-pill small"
            onClick={triggerFileInput}
            disabled={onboardingProgress.includes(3)}>
            Upload logo
          </button>
        )}

        {uploading && (
          <button className="button-pill small" disabled>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
            Loading...
          </button>
        )}
      </div>
    </div>
  );
};

export default OnboardingLogo;
