import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import placeholderPhoto from "/user-pic.png";
import "./CandidateProfile.css";

const CandidateProfileForm = ({
  profileData,
  handleFormState,
  handlePhotoChange,
  uploading,
  progress,
  onSubmit
}) => {
  const [formData, setFormData] = useState({
    name: profileData.name || "",
    title: profileData.profTitle || "",
    location: profileData.location || "",
    status: profileData.status || "",
    email: profileData.email || "",
    description: profileData.description || ""
  });

  const statusOptions = [
    { value: "actively-seeking", label: "Actively Seeking" },
    { value: "open-to-opportunities", label: "Open to New Opportunities" },
    { value: "not-actively-seeking", label: "Not Actively Seeking" }
  ];

  // Update the formData when profileData changes
  useEffect(() => {
    setFormData({
      name: profileData.name || "",
      title: profileData.profTitle || "",
      location: profileData.location || "",
      status: profileData.status || "",
      email: profileData.email || "",
      description: profileData.description || ""
    });
  }, [profileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    const success = await onSubmit(formData);
    if (success) handleFormState();
  };

  const triggerFileInput = () => {
    document.getElementById("uploadProfilePic").click();
  };

  return (
    <div className="card p-4 d-flex flex-col gap-2 mb-2">
      <h5>Update Your Profile</h5>
      <div className="d-flex flex-column align-items-center gap-2 me-auto">
        <img
          className="form-user-photo"
          src={profileData.photo ? profileData.photo : placeholderPhoto}
          alt="user photo"
        />
        <input
          type="file"
          onChange={handlePhotoChange}
          accept="image/*"
          style={{ display: "none" }}
          id="uploadProfilePic"
        />
        <label htmlFor="uploadProfilePic">
          <button className="button-pill mb-3" type="button" onClick={triggerFileInput}>
            {uploading ? `Uploading ${Math.round(progress)}%` : "Add Photo"}
          </button>
        </label>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Full Name"
            onChange={handleChange}
            value={formData.name}
          />
          <label htmlFor="name" className="public-sans-regular">
            Full Name
          </label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            placeholder="Professional Title(s)"
            onChange={handleChange}
            value={formData.title}
          />
          <label htmlFor="title" className="public-sans-regular">
            Professional Title(s)
          </label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            placeholder="Current Location"
            onChange={handleChange}
            value={formData.location}
          />
          <label htmlFor="location" className="public-sans-regular">
            Current Location (City, State)
          </label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control-plaintext"
            id="email"
            name="email"
            value={formData.email}
            readOnly
          />
          <label htmlFor="email" className="public-sans-regular">
            Email Address
          </label>
        </div>
        <div className="form-floating mb-3">
          <select
            className="form-select"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}>
            <option value="" disabled>
              What's your status?
            </option>
            {statusOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <label htmlFor="status" className="public-sans-regular">
            Employment Status
          </label>
        </div>

        <div className="form-floating mb-3">
          <textarea
            className="form-control"
            id="description"
            name="description"
            onChange={handleChange}
            value={formData.description}
            style={{ height: "150px" }}
          />
          <label htmlFor="description" className="public-sans-regular">
            Professional Description
          </label>
        </div>
      </form>
      <div className="d-flex justify-content-between align-items-center">
        <Link className="text-decoration-none" onClick={handleFormState}>
          Nevermind
        </Link>
        <button className="button-pill" onClick={handleSubmit}>
          Update Profile
        </button>
      </div>
    </div>
  );
};

export default CandidateProfileForm;
