import { Link } from "react-router-dom";

const ConnectionsNav = ({ companyId, handleLinkClick, displayActiveLink }) => {
  return (
    <nav className="nav connections-nav mb-3">
      {companyId && (
        <Link
          onClick={() => handleLinkClick("all")}
          className={`nav-link ${displayActiveLink === "all" ? "active" : ""}`}>
          All Candidates
        </Link>
      )}
      <Link
        onClick={() => handleLinkClick("received")}
        className={`nav-link ${displayActiveLink === "received" ? "active" : ""}`}>
        Received
      </Link>
      <Link
        onClick={() => handleLinkClick("sent")}
        className={`nav-link ${displayActiveLink === "sent" ? "active" : ""}`}>
        Sent
      </Link>
      <Link
        onClick={() => handleLinkClick("accepted")}
        className={`nav-link ${displayActiveLink === "accepted" ? "active" : ""}`}>
        Connected
      </Link>
    </nav>
  );
};

export default ConnectionsNav;
