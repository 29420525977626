import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import LoadingSpinner from "../LoadingSpinner";
import CandidateProfileInfoSection from "./CandidateProfileInfoSection";
import CandidateProfileForm from "./CandidateProfileForm";
import CandidatePreferences from "./CandidatePreferences";
import CandidateExperience from "./CandidateExperience";
import CandidateEducation from "./CandidateEducation";
import profilePic from "/user-pic.png";
import CandidateResume from "./CandidateResume";

const CandidateProfile = () => {
  const { currentUser } = useAuth();
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  // FETCH USER PROFILE DATA
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!currentUser) {
          console.log("no current user");
          navigate("/");
        }
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
          throw new Error("No user exists with this ID");
        }
        setProfileData(userDoc.data());
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("error fetching user data: ", error.message);
      }
    };
    fetchUserData();
  }, [currentUser, navigate]);

  const toggleProfileEditForm = () => {
    setIsEditingProfile((prevState) => !prevState);
  };

  // UPDATE USER PROFILE DATA IN FIRESTORE
  // Function can be used by multiple components
  // since we are only submitting updated data and
  // not overwriting the entire document

  const updateProfileData = async (data) => {
    try {
      setLoading(true);
      const userDocRef = doc(db, "users", currentUser.uid);

      // Prepare the fields to update, excluding any undefined values
      const updateFields = {
        profTitle: data.title || profileData.profTitle,
        location: data.location || profileData.location,
        status: data.status || profileData.status,
        email: data.email || profileData.email,
        description: data.description || profileData.description,
        photo: data.photo || profileData.photo,
        locationPrefs: data.locationPrefs || profileData.locationPrefs,
        experience: data.experience || profileData.experience,
        education: data.education || profileData.education,
        name: data.name || profileData.name,
        resume: data.resume || profileData.resume
      };

      // Remove any undefined fields from updateFields
      Object.keys(updateFields).forEach(
        (key) => updateFields[key] === undefined && delete updateFields[key]
      );

      // Update Firebase with the cleaned-up updateFields object
      await updateDoc(userDocRef, updateFields);

      // Update the local profileData state, merging with the previous state
      setProfileData((prevData) => ({
        ...prevData,
        ...updateFields
      }));

      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      console.error("Error updating profile: ", error.message);
      return false;
    }
  };

  // CANDIDATE PHOTO LOGIC
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2mb file size

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      alert("File is too large! Please upload a file smaller than 2MB.");
      return;
    }

    uploadFile(file);
  };

  const uploadFile = async (file) => {
    const storageRef = ref(storage, `candidate_profile_photos/${currentUser.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed: ", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await saveFileURL(downloadURL);
        setUploading(false);
      }
    );
  };

  const saveFileURL = async (downloadURL) => {
    try {
      await updateProfileData({ photo: downloadURL });
    } catch (error) {
      console.error("Error saving profile photo URL to firestore: ", error);
    }
  };

  // CANDIDATE RESUME LOGIC
  const MAX_RESUME_FILE_SIZE = 5 * 1024 * 1024; // 2mb file size

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > MAX_RESUME_FILE_SIZE) {
      alert("File is too large! Please upload a file smaller than 2MB.");
      return;
    }

    uploadResumeFile(file);
  };

  const uploadResumeFile = async (file) => {
    const storageRef = ref(storage, `candidate_resume/${currentUser.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed: ", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await saveResumeFileURL(downloadURL);
        setUploading(false);
      }
    );
  };

  const saveResumeFileURL = async (downloadURL) => {
    try {
      await updateProfileData({ resume: downloadURL });
    } catch (error) {
      console.error("Error saving resume URL to firestore: ", error);
    }
  };

  // CANDIDATE PREFERRENCES LOGIC
  // Add location to locationPrefs array
  const addLocation = async (location) => {
    const updatedLocationPrefs = [
      ...(profileData.locationPrefs || []), // ensure the array exists
      location
    ];

    const success = await updateProfileData({ locationPrefs: updatedLocationPrefs });
    if (success) {
      setProfileData((prevData) => ({
        ...prevData,
        locationPrefs: updatedLocationPrefs
      }));
    } else {
      console.log("error updating preference in firestore");
    }
  };

  // Delete location from the locationPrefs array
  const deleteLocation = async (location) => {
    const updatedLocationPrefs = (profileData.locationPrefs || []).filter(
      (pref) => pref !== location
    );

    const success = await updateProfileData({ locationPrefs: updatedLocationPrefs });
    if (success) {
      setProfileData((prevData) => ({
        ...prevData,
        locationPrefs: updatedLocationPrefs
      }));
    }
  };

  // Candidate Experience Logic
  const saveExperience = async (experienceData) => {
    const success = await updateProfileData({ experience: experienceData });
    if (success) {
      setProfileData((prevData) => ({
        ...prevData,
        experience: experienceData
      }));
    }
  };

  // Candidate Education Logic
  const saveEducation = async (educationData) => {
    const success = await updateProfileData({ education: educationData });
    if (success) {
      setProfileData((prevData) => ({
        ...prevData,
        education: educationData
      }));
    }
  };

  return (
    <section className="candidate-profile">
      {loading && <LoadingSpinner />}

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="public-sans-bold">Profile</h3>
        <img
          src={profileData.photo ? profileData.photo : profilePic}
          alt="profile photo"
          className="mobile-profile-photo"
        />
      </div>

      {!isEditingProfile ? (
        <CandidateProfileInfoSection
          name={profileData.name}
          title={profileData.profTitle}
          location={profileData.location}
          phone={profileData.phoneNumber}
          email={profileData.email}
          status={profileData.status}
          description={profileData.description}
          photo={profileData.photo}
          handleFormState={toggleProfileEditForm}
        />
      ) : (
        <CandidateProfileForm
          profileData={profileData}
          handleFormState={toggleProfileEditForm}
          handlePhotoChange={handlePhotoChange}
          uploading={uploading}
          progress={progress}
          onSubmit={updateProfileData}
        />
      )}

      <CandidatePreferences
        locationPrefs={profileData.locationPrefs}
        addLocation={addLocation}
        deleteLocation={deleteLocation}
      />

      <CandidateExperience
        experience={profileData.experience}
        yearsExperience={profileData.yearsExperience}
        saveExperience={saveExperience}
      />
      <CandidateEducation education={profileData.education} saveEducation={saveEducation} />
      <CandidateResume
        handleResumeChange={handleResumeChange}
        uploading={uploading}
        progress={progress}
        resume={profileData.resume}
      />
      {/* <div className="card p-4 d-flex flex-col gap-2 mb-2">
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <h4>Skills</h4>
          <Link to="#" className="d-flex gap-2">
            <i className="bi-pencil-square"></i>
            Edit
          </Link>
        </div>

        <hr />
      </div> */}
    </section>
  );
};

export default CandidateProfile;
