import { useState } from "react";
import { Link } from "react-router-dom";

const NPIForm = ({ submitNpiNumber, skipNpiNumber, loading, data }) => {
  const [number, setNumber] = useState("");

  const handleSubmitNumber = (e) => {
    e.preventDefault();
    submitNpiNumber(number);
  };

  const handleSkipNumber = () => {
    skipNpiNumber();
  };

  return (
    <>
      <div className={`card ${data && "theme-dark"}`}>
        <div className="d-flex justify-content-between align-items-center py-2 px-3">
          <div className="d-flex justify-content-start align-items-top gap-3">
            <i className="bi-patch-check" style={{ fontSize: "2rem" }}></i>
            <div>
              <h6 className="mb-0">Add your NPI number</h6>
              <span className="small fw-lighter">
                We'll use your NPI number to help you complete your profile faster
              </span>
            </div>
          </div>
        </div>
        <div className="py-2 px-3">
          {!data ? (
            <>
              {/* <div className="d-flex justify-content-between align-items-center gap-3 mb-2"> */}
              <input
                type="number"
                className="form-control"
                id="npi-number"
                name="npi-number"
                placeholder="1234567890"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
              />
              <div className="d-flex justify-content-between align-items-center gap-3 mt-3">
                <Link className="text-decoration-none small" onClick={handleSkipNumber}>
                  Skip
                </Link>
                {!loading ? (
                  <button
                    className="button-pill small"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={handleSubmitNumber}>
                    Add NPI
                  </button>
                ) : (
                  <button
                    className="button-pill small d-flex gap-2 align-items-center"
                    style={{ whiteSpace: "nowrap" }}
                    disabled>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span role="status">Loading...</span>
                  </button>
                )}
              </div>

              {/* </div> */}
            </>
          ) : (
            <div className="ps-5">
              <p className="small fw-bolder">You entered: {number}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NPIForm;
