import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { Link } from "react-router-dom";
import logoWhite from "/Medis_Off-White.png";
import { useAuth } from "../context/AuthContext";
import AuthRedirect from "../AuthRedirect";
import ForgotPassword from "../components/ForgotPassword";
import { sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";
import { auth } from "../firebase/config"; // Your Firebase config file
import Modal from "../components/Modal";
import ResetPassword from "../components/ResetPassword";
import { logEvent, analytics } from "../firebase/config";

const Login = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [searchParams] = useSearchParams();

  // Allow user to request a password reset by providing their email address
  const [displayForgotPasswordForm, setDisplayForgotPasswordForm] = useState(false);

  // Logs a custom event when a user loads the login page
  useEffect(() => {
    logEvent(analytics, "login_start");
  }, []);

  const toggleForgotPasswordForm = () => {
    setDisplayForgotPasswordForm((prevState) => !prevState);
  };

  const resetPasswordEmail = async (email) => {
    setLoading(true);
    setError("");
    setMessage("");

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("An email has been sent to your email address.");
    } catch (error) {
      setError("Failed to send password reset email. Try again.");
      console.error("Error sending password reset email:", error);
    } finally {
      setLoading(false);
      setDisplayModal(true);
    }
  };

  // Handle new password submission

  const oobCode = searchParams.get("oobCode");

  const handlePasswordReset = async (password) => {
    setLoading(true);
    setError("");
    setMessage("");

    try {
      if (!oobCode) {
        throw new Error("Invalid or missing reset code.");
      }

      await confirmPasswordReset(auth, oobCode, password);
      setMessage(
        "Your password has been successfully reset. You can now log in with your new password."
      );
    } catch (error) {
      setError("Failed to reset password. Please try again.");
      console.error("Error resetting password:", error);
    } finally {
      setLoading(false);
      setDisplayModal(true);
    }
  };

  const toggleModal = () => {
    setDisplayModal((prevState) => !prevState);
  };

  if (currentUser) {
    return <AuthRedirect />;
  }
  return (
    <>
      {displayModal && (
        <Modal title="Reset Password" onClose={toggleModal}>
          {message}
        </Modal>
      )}

      <main className="login container">
        <div className="row h-100">
          <div className="col-md d-none d-sm-block">
            <div className="brand-col">
              <div className="brand-section">
                <Link to="/">
                  <img src={logoWhite} alt="" />
                </Link>

                <h3 className="public-sans-regular my-4">Welcome back!</h3>
                <p className="public-sans-regular tagline">
                  Medis is the dental recruiting platform built by dental recruiters for dentists
                  and practice owners like you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md form-col">
            <div className="form-section">
              {!oobCode ? (
                <>
                  <h2 className="public-sans-bold mb-4">
                    {displayForgotPasswordForm
                      ? "Forgot your password?"
                      : "Let's get you logged in"}
                  </h2>
                  {displayForgotPasswordForm ? (
                    <ForgotPassword
                      onClick={toggleForgotPasswordForm}
                      resetPasswordEmail={resetPasswordEmail}
                      loading={loading}
                    />
                  ) : (
                    <LoginForm onClick={toggleForgotPasswordForm} />
                  )}
                </>
              ) : (
                <>
                  <h2 className="public-sans-bold mb-4">Reset your password</h2>
                  <ResetPassword
                    handlePasswordReset={handlePasswordReset}
                    loading={loading}
                    message={message}
                    error={error}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
