import { db } from "../firebase/config";
import { collection, doc, addDoc, onSnapshot } from "firebase/firestore";

// Function to initiate a one-time payment with Stripe
export const initializeStripeCheckout = async (currentUser, priceId, jobId) => {
  try {
    // Reference to the collection for current user's checkout sessions
    const checkoutSessionRef = collection(db, "users", currentUser.uid, "checkout_sessions");

    // Create a checkout session document
    const docRef = await addDoc(checkoutSessionRef, {
      mode: "payment",
      price: priceId,
      success_url: `${window.location.origin}/company/jobs?payment_status=success&session_id={CHECKOUT_SESSION_ID}&jobId=${jobId}`,
      cancel_url: `${window.location.origin}/company/jobs?payment_status=cancel`, // clear stripeSessionID from job?
      metadata: { jobId }
    });

    // Correctly pass the initialized `docRef` to `onSnapshot`
    onSnapshot(doc(db, docRef.path), (snap) => {
      const { error, url } = snap.data();

      if (error) {
        // Handle any errors that occur during session creation
        console.error("An error occurred during Stripe checkout: ", error.message);
        alert(`Error: ${error.message}`);
      }

      if (url) {
        // Redirect to Stripe checkout page
        window.location.assign(url);
      }
    });
    return { success: true, docRef }; // Return session ID here
  } catch (error) {
    console.error("Error initiating Stripe checkout session: ", error);
    return { success: false, error };
  }
};
