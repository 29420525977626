import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, updateDoc, setDoc, collection, getDoc, getDocs } from "firebase/firestore"; // Firebase Firestore functions
import { db } from "../../firebase/config";
import DOMPurify from "dompurify";
import FormattingEditor from "../FormattingEditor";
import LoadingSpinner from "../LoadingSpinner";

const AddJobForm = ({ companyId, handleBack, fetchJobs, editingJob }) => {
  const [jobData, setJobData] = useState({
    jobTitle: "",
    jobDescription: "",
    location: "",
    jobResponsibilities: "",
    education: "",
    licenses: "",
    experience: "",
    skills: "",
    salaryMin: "",
    salaryMax: "",
    compensationInfo: "",
    benefits: "",
    schedule: "",
    isFullTime: "",
    isPublished: "",
    publishDate: "",
    stripeTransactionId: ""
  });
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (companyId) {
        const companyDoc = await getDoc(doc(db, "companies", companyId));
        if (companyDoc.exists()) {
          setCompanyData(companyDoc.data());
        } else {
          console.error("Company not found");
        }
      }
    };

    const fetchLocations = async () => {
      if (companyId) {
        const locationsSnapshot = await getDocs(
          collection(db, "companies", companyId, "locations")
        );
        const fetchedLocations = locationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setLocations(fetchedLocations);
      }
    };
    fetchCompanyData();
    fetchLocations();

    if (editingJob) {
      console.log("Editing job location:", editingJob.location); // Debugging

      setJobData({
        jobTitle: editingJob.jobTitle || "",
        jobDescription: editingJob.jobDescription || "",
        location: editingJob.location || "",
        jobResponsibilities: editingJob.jobResponsibilities || "",
        education: editingJob.education || "",
        licenses: editingJob.licenses || "",
        experience: editingJob.experience || "",
        skills: editingJob.skills || "",
        salaryMin: editingJob.salaryMin || "",
        salaryMax: editingJob.salaryMax || "",
        compensationInfo: editingJob.compensationInfo || "",
        benefits: editingJob.benefits || "",
        schedule: editingJob.schedule || "",
        isFullTime: editingJob.isFullTime || "",
        isPublished: editingJob.isPublished || "",
        publishDate: editingJob.publishDate || "",
        stripeTransactionId: editingJob.stripeTransactionId || ""
      });
    }
  }, [editingJob, companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "salaryMin" || name === "salaryMax") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Strip non-numeric characters
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas

      setJobData((prevState) => ({
        ...prevState,
        [name]: formattedValue
      }));
    } else {
      setJobData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleEditorChange = (e) => {
    const { name, value } = e.target;
    const sanitizedHtml = DOMPurify.sanitize(value, {
      ALLOWED_TAGS: ["b", "i", "em", "strong", "ul", "ol", "li", "br", "p"]
    });
    // console.log(sanitizedHtml);
    setJobData((prevData) => ({
      ...prevData,
      [name]: sanitizedHtml
    }));
    // console.log(`updated ${name}:`, sanitizedHtml);
    // console.log("job data:", jobData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const jobPayload = {
        ...jobData,
        salaryMin: jobData.salaryMin.replace(/,/g, ""), // Strip commas
        salaryMax: jobData.salaryMax.replace(/,/g, ""), // Strip commas
        companyId,
        companyName: companyData?.name || "",
        createdAt: new Date().toISOString()
      };

      if (editingJob) {
        await updateDoc(doc(db, "jobs", editingJob.id), jobPayload);
      } else {
        const jobCollectionRef = collection(db, "jobs");
        await setDoc(doc(jobCollectionRef), jobPayload);
      }

      fetchJobs();
      handleBack();
    } catch (error) {
      console.error("Error adding job:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            {/* Job Title */}
            <h4 className="mb-3">Essential Information</h4>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="jobTitle"
                name="jobTitle"
                value={jobData.jobTitle}
                onChange={handleChange}
                placeholder="Job Title"
                required
              />
              <label htmlFor="jobTitle">Job Title</label>
            </div>

            {/* Job Description */}
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                id="jobDescription"
                name="jobDescription"
                value={jobData.jobDescription}
                onChange={handleChange}
                placeholder="Job Summary"
                required
                style={{ height: "100px" }}
              />
              <label htmlFor="jobDescription">Job Summary</label>
            </div>

            {/* Location Dropdown */}
            <div className="form-floating mb-3">
              <select
                className="form-select"
                id="location"
                name="location"
                value={jobData.location}
                onChange={handleChange}
                required>
                <option value="">Select Location</option>
                {locations.map((location) => (
                  <option key={location.id} value={location.displayName || location.city}>
                    {`${location.address1}, ${location.city}, ${location.state}`}
                  </option>
                ))}
              </select>
              <label htmlFor="location">Location</label>
            </div>

            {/* add fields for reloaction required and will pay for relocation */}

            {/* Additional Fields for Responsibilities and Qualifications */}
            <div className="mb-3">
              <label htmlFor="jobResponsibilities" className="my-2 public-sans-regular">
                Job Responsibilities
              </label>
              <FormattingEditor
                value={jobData.jobResponsibilities}
                onChange={handleEditorChange}
                name="jobResponsibilities"
                id="jobResponsibilities"
                placeholder="Add some job responsibilites"
              />
            </div>

            {/* Qualifications */}
            <h4 className="mb-3 mt-5">Candidate Qualifications</h4>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="education"
                name="education"
                value={jobData.education}
                onChange={handleChange}
                placeholder="Education"
              />
              <label htmlFor="education">Education</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="licenses"
                name="licenses"
                value={jobData.licenses}
                onChange={handleChange}
                placeholder="Licenses"
              />
              <label htmlFor="licenses">Licenses</label>
            </div>
            <div className="mb-3">
              <label htmlFor="experience" className="my-2 public-sans-regular">
                Experience
              </label>
              <FormattingEditor
                value={jobData.experience}
                onChange={handleEditorChange}
                name="experience"
                id="experience"
                placeholder="Add some experience requirements"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="skills" className="my-2 public-sans-regular">
                Skills Required
              </label>
              <FormattingEditor
                value={jobData.skills}
                onChange={handleEditorChange}
                name="skills"
                id="skills"
                placeholder="Add some additional skills"
              />
            </div>

            {/* Compensation and Benefits */}
            {/* Salary */}
            <h4 className="mb-3 mt-5">Compensation & Benefits</h4>
            <div className="d-flex justify-content-between align-items-center gap-3">
              <div className="form-floating mb-3 flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  id="salaryMin"
                  name="salaryMin"
                  value={jobData.salaryMin}
                  onChange={handleChange}
                  placeholder="Min. Salary"
                />
                <label htmlFor="salaryMin">Min. Salary</label>
              </div>
              <div className="form-floating mb-3 flex-grow-1">
                <input
                  type="text"
                  className="form-control"
                  id="salaryMax"
                  name="salaryMax"
                  value={jobData.salaryMax}
                  onChange={handleChange}
                  placeholder="Max Salary"
                />
                <label htmlFor="salaryMax">Max Salary</label>
              </div>
            </div>
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                id="compensationInfo"
                name="compensationInfo"
                value={jobData.compensationInfo}
                onChange={handleChange}
                placeholder="Additional Compensation Information"
                style={{ height: "100px" }}
              />
              <label htmlFor="compensationInfo">Additional Compensation Information</label>
            </div>
            {/* Benefits */}

            <div className="mb-3">
              <label htmlFor="benefits" className="my-2 public-sans-regular">
                Benefits
              </label>
              <FormattingEditor
                value={jobData.benefits}
                onChange={handleEditorChange}
                name="benefits"
                id="benefits"
                placeholder="List your benefits"
              />
            </div>

            {/* Schedule */}
            <h4 className="mb-3 mt-5">Schedule</h4>

            {/* Full Time / Part Time Dropdown */}
            <div className="form-floating mb-3">
              <select
                className="form-select"
                id="isFullTime"
                name="isFullTime"
                value={jobData.isFullTime}
                onChange={handleChange}>
                <option value="">Select One</option>
                <option value="Full-Time">Full-Time</option>
                <option value="Part-Time">Part-Time</option>
              </select>
              <label htmlFor="isFullTime">Full-Time or Part-Time?</label>
            </div>

            <div className="form-floating mb-3">
              <textarea
                name="schedule"
                id="schedule"
                className="form-control"
                value={jobData.schedule}
                onChange={handleChange}
                placeholder="Schedule"
                style={{ height: "100px" }}
              />
              <label htmlFor="schedule">Schedule Information</label>
            </div>

            <div className="d-flex align-content-center gap-3">
              <button type="submit" className="button-pill">
                {editingJob ? "Update Job" : "Add Job"}
              </button>
              <Link className="my-3 text-decoration-none" onClick={handleBack}>
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddJobForm;
