import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App.jsx";
import Login from "./routes/Login.jsx";
import CreateAccount from "./routes/CreateAccount.jsx";
import Dashboard from "./routes/Dashboard.jsx";
import Profile from "./routes/Profile.jsx";
import Onboarding from "./routes/Onboarding.jsx";
import PublicCompanyProfile from "./routes/PublicCompanyProfile.jsx";
import CompanyProfile from "./components/company/CompanyProfile.jsx";
import CompanyJobsList from "./components/company/CompanyJobsList.jsx";
import CompanyCandidates from "./components/company/CompanyCandidates.jsx";
import CandidateJobsList from "./components/candidate/CandidateJobsList.jsx";
import CandidateProfile from "./components/candidate/CandidateProfile.jsx";
import PublicJobPosting from "./routes/PublicJobPosting.jsx";
import PublicCandidateProfile from "./routes/PublicCandidateProfile.jsx";
import { AuthProvider } from "./context/AuthContext.jsx";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import CandidateConnections from "./components/candidate/CandidateConnections.jsx";
import Connections from "./components/Connections.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "company",
        children: [
          { path: "jobs", element: <CompanyJobsList /> },
          { path: "profile", element: <CompanyProfile /> },
          { path: "candidates", element: <CompanyCandidates /> },
          { path: "network", element: <Connections /> }
        ]
      },
      {
        path: "candidate",
        children: [
          { path: "jobs", element: <CandidateJobsList /> },
          { path: "profile", element: <CandidateProfile /> },
          { path: "onboarding", element: <Onboarding /> },
          { path: "network", element: <Connections /> }
        ]
      },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/profile", element: <Profile /> },
      { path: "/companies/:id", element: <PublicCompanyProfile /> },
      { path: "/jobs/:id", element: <PublicJobPosting /> },
      { path: "candidates/:id", element: <PublicCandidateProfile /> }
    ]
  },
  { path: "/login", element: <Login /> },
  { path: "/create-account", element: <CreateAccount /> }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
