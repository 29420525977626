import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { doc, getDoc, query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import Navbar from "../components/Navbar";
import LoadingSpinner from "../components/LoadingSpinner";
import Job from "../components/Job";
import "./PublicCompanyProfile.css";

const PublicCompanyProfile = () => {
  const { id } = useParams();
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyData = async () => {
      setLoading(true);
      try {
        const companyDocRef = doc(db, "companies", id);
        const companyDoc = await getDoc(companyDocRef);

        if (companyDoc.exists()) {
          setCompanyData(companyDoc.data());
          fetchJobsForCompany(id);
        } else {
          console.error("company not found");
        }
      } catch (error) {
        console.error("Eerror fetching company data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchJobsForCompany = async (companyId) => {
      try {
        const jobsQuery = query(
          collection(db, "jobs"),
          where("companyId", "==", companyId),
          where("isPublished", "==", true)
        );
        const jobsSnapshot = await getDocs(jobsQuery);

        const jobsList = jobsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setJobs(jobsList);
      } catch (error) {
        console.error("Error fetching jobs for company:", error);
      }
    };

    fetchCompanyData();
  }, [id]);

  if (loading) {
    return <div>Loading company data...</div>;
  }

  if (!companyData) {
    return <div>Company not found...</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section className="profile public-sans-regular">
        <button className="button-pill mb-4" onClick={handleBack}>
          Go Back
        </button>
        {loading && <LoadingSpinner />}
        <div className="card p-4 d-flex flex-col gap-2 mb-2">
          <div className="d-flex flex-row gap-4 mb-2">
            <div className="office-logo-container mb-0">
              <img src={companyData.logo} className="office-logo" alt="office-logo" />
            </div>

            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between align-items-center">
                <h4>{companyData.name}</h4>
              </div>
              <div>{companyData.description}</div>
              <div className="d-flex gap-2">
                <i className="bi-box-arrow-up-right"></i>
                <Link
                  to={companyData.website}
                  target="_blank">{`Visit ${companyData.name} website`}</Link>
              </div>
            </div>
          </div>
        </div>

        {companyData.aboutOurCompany && (
          <div className="card mb-2 p-4">
            <h4 className="mb-2">About {companyData.name}</h4>
            {companyData.aboutOurCompany.map((section, index) => (
              <div key={index} className="mb-2">
                <hr />
                <h5>{section.sectionTitle}</h5>
                <p>{section.sectionBody}</p>
              </div>
            ))}
          </div>
        )}
        {/* Render office photos if any exist */}
        {companyData.officePhotos && companyData.officePhotos.length > 0 && (
          <div className="card p-4 mb-2">
            <div className="d-flex justify-content-between align-content-center">
              <h4>Office Photos</h4>
            </div>
            <div className="d-flex flex-wrap gap-2 my-2">
              {companyData.officePhotos.map((photo, index) => (
                <div className="office-photo-container" key={index}>
                  <img src={photo} alt={`office-photo-${index}`} width="150" height="150" />
                </div>
              ))}
            </div>
          </div>
        )}
        {/* Render Jobs Section */}
        {jobs && jobs.length > 0 && (
          <div className="card mb-2 p-4">
            <h4 className="mb-2">Open Positions at {companyData.name}</h4>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
              {jobs.map((job) => {
                const formattedDate = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                }).format(new Date(job.createdAt));
                return (
                  <div className="col" key={job.id}>
                    <Job
                      id={job.id}
                      name={job.jobTitle}
                      postDate={formattedDate}
                      description={job.jobDescription}
                      location={job.location}
                      salaryRange={job.salaryRange}
                      isFullTime={job.isFullTime}
                      isDisplayedOnProfile
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default PublicCompanyProfile;
