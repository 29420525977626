import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/config";

// create context for authentication
const AuthContext = createContext();

//custom hook to use AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// authprovider component that wrpas the app adn provides authentication state
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // ensure loading is set to false after auth check
    });
    //cleanup subscription on unmount
    return unsubscribe;
  }, []);

  const logout = () => {
    return signOut(auth);
  };
  const value = {
    currentUser,
    setCurrentUser,
    logout
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      {/*only render children when loading is complete */}
    </AuthContext.Provider>
  );
};
