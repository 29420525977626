import { useState } from "react";

const OnboardingWebsite = ({ companyData, onboardingProgress, saveCompanyWebsite }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [website, setWebsite] = useState("");

  const isWebsiteComplete =
    companyData?.onboardingCompleted?.includes(5) || onboardingProgress.includes(5);

  const toggleFormOpen = () => {
    setFormOpen((prevState) => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCompanyWebsite(website);
    toggleFormOpen();
  };

  return (
    <div className={`card mb-3 ${isWebsiteComplete && "theme-dark"}`}>
      <div className="d-flex justify-content-between align-items-center py-2 px-3">
        <div className="d-flex justify-content-start align-items-center gap-3">
          <i className="bi-link" style={{ fontSize: "2rem" }}></i>
          <div>
            <h6 className="mb-0">Add your website</h6>
            <span className="small fw-lighter">Help everyone find you online</span>
          </div>
        </div>
        {isWebsiteComplete && (
          <span className="">
            <i className="bi-check-lg" />
          </span>
        )}
        {!isWebsiteComplete && !formOpen && (
          <button className="button-pill small" onClick={toggleFormOpen}>
            Add URL
          </button>
        )}
        {!isWebsiteComplete && formOpen && <i className="bi-x-circle" onClick={toggleFormOpen}></i>}
      </div>
      {formOpen && (
        <form onSubmit={handleSubmit} className="p-3">
          <div className="mb-3">
            <input
              type="url"
              className="form-control"
              id="website"
              name="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="Enter a valid URL (https://...)"
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Please enter a valid URL, starting with http:// or https://"
                )
              }
              onInput={(e) => e.target.setCustomValidity("")}
            />
            {/* <label htmlFor="description" className="small">
              Company Website
            </label> */}
          </div>
          <button className="button-pill small" type="submit">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default OnboardingWebsite;
