import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDoc, collection } from "firebase/firestore";
import { auth, db } from "../firebase/config";
import { getErrorMessage } from "../utils/authErrors";

//Components
import CreateAccountForm from "../components/CreateAccountForm";
import Modal from "../components/Modal";

//Styles and Images
import logoWhite from "/Medis_Off-White.png";
import "./CreateAccount.css";
import CreateCompanyAccountForm from "../components/company/CreateCompanyAccountForm";

const CreateAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formType, setFormType] = useState("individual"); // Default to individual form
  const [isCompany, setIsCompany] = useState(false);
  const [isDentist, setIsDentist] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Get the "type" query param, e.g., ?type=company
    const type = searchParams.get("type");
    if (type === "company") {
      setFormType("company");
      setIsCompany(true);
      setIsDentist(false);
    } else if (type === "dentist") {
      setFormType("dentist");
      setIsDentist(true);
      setIsCompany(false);
    } else {
      setFormType("dentist");
      setIsDentist(true);
      setIsCompany(false);
    }
  }, [searchParams]);

  // Update the URL with the query param for company form
  const handleSetIsCompany = () => {
    navigate("/create-account?type=company");
    setIsDentist(false);
    setIsCompany(true);
  };

  // Update the URL with the query param for dentist form
  const handleSetIsDentist = () => {
    navigate("/create-account?type=dentist");
    setIsDentist(true);
    setIsCompany(false);
  };

  // Handle create account form submission
  const createAccountSubmission = async (formData, userType) => {
    setLoading(true);
    setError(null);

    try {
      // Step 1 -> Create new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      // Step 2 -> Create a new company document
      let companyId = null;
      if (formData.companyName) {
        const companyDocRef = doc(collection(db, "companies"));
        companyId = companyDocRef.id;
        await setDoc(companyDocRef, {
          name: formData.companyName,
          createdAt: new Date().toISOString(),
          onboardingCompleted: [1],
          logo: ""
        });
      }

      // Step 3 -> Add user to users collection with a reference to the company
      const userDocRef = doc(db, "users", user.uid);
      const userData = {
        email: formData.email,
        role: userType,
        createdAt: new Date().toISOString()
      };

      // Only add company information if it exists
      if (formData.companyName) {
        userData.companyName = formData.companyName;
        userData.companyId = companyId;
      }
      await setDoc(userDocRef, userData);

      // Step 4 -> Redirect to logged-in application
      // if the user is a company user take them somewhere, if it's a candidate take them elsewhere
      if (companyId) {
        navigate("/company/profile");
      } else {
        navigate("/candidate/onboarding");
      }

      // Return success to form component
      return true;
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      console.error("Error creating account:", error.code, error.message);
      setError(errorMessage);
      setLoading(false);

      // Indicate to form that registration is unsuccessful, do not clear form
      return false;
    } finally {
      setLoading(false);
    }
  };

  const toggleLoading = () => {
    setLoading((prevLoading) => !prevLoading);
  };

  const clearModal = () => {
    setError(null);
  };

  return (
    <>
      {error && (
        <Modal title="Error creating your account" onClose={clearModal}>
          {error}
        </Modal>
      )}
      <main className="create-account container">
        <div className="row">
          <div className="col-md d-none d-sm-block">
            <div className="brand-col">
              <div className="brand-section">
                <Link to="/">
                  <img src={logoWhite}></img>
                </Link>
                <h3 className="public-sans-regular my-4">Let's get started!</h3>

                <p className="public-sans-regular tagline">
                  Medis the hiring platform built by dental recruiters for dentists and practice
                  owners like you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md form-col">
            {isDentist && (
              <div className="form-section">
                <h2 className="public-sans-bold mb-4">Ready to begin your journey?</h2>
                <CreateAccountForm
                  onSubmit={createAccountSubmission}
                  loading={loading}
                  toggleLoading={toggleLoading}
                  error={error}
                />
                <Link
                  onClick={handleSetIsCompany}
                  className="public-sans-regular text-decoration-none small">
                  Registering as a company?
                </Link>
              </div>
            )}
            {isCompany && (
              <div className="form-section">
                <h2 className="public-sans-bold mb-4">Ready to find standout talent?</h2>
                <CreateCompanyAccountForm
                  onSubmit={createAccountSubmission}
                  loading={loading}
                  toggleLoading={toggleLoading}
                  error={error}
                />
                <Link
                  onClick={handleSetIsDentist}
                  className="public-sans-regular text-decoration-none small">
                  Registering as an individual?
                </Link>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateAccount;
