import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Navbar from "../components/Navbar";
import LoadingSpinner from "../components/LoadingSpinner";
import FormattedText from "../components/FormattedText";
import { useAuth } from "../context/AuthContext";
import { useConnections } from "../context/ConnectionsContext";
import { createConnection } from "../services/ConnectionService";

const PublicJobPosting = () => {
  const { id } = useParams();
  const [jobData, setJobData] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const { getConnection } = useConnections();
  const navigate = useNavigate();

  const fetchJobAndConnectionData = useCallback(async () => {
    setLoading(true);
    try {
      // Fetch Job Data
      const jobDocRef = doc(db, "jobs", id);
      const jobDoc = await getDoc(jobDocRef);

      if (jobDoc.exists()) {
        const job = jobDoc.data();
        setJobData(job);

        if (job.companyId) {
          // Fetch company data
          const companyDocRef = doc(db, "companies", job.companyId);
          const companyDoc = await getDoc(companyDocRef);

          if (companyDoc.exists()) {
            setCompanyData(companyDoc.data());
          } else {
            console.error("Company does not exist.");
          }

          // Fetch connection status
          if (currentUser) {
            const connection = await getConnection(currentUser.uid, job.companyId);
            setConnectionStatus(connection ? connection.status : null);
          }
        }
      } else {
        console.error("Job not found.");
      }
    } catch (error) {
      console.error("Error fetching job data:", error);
    } finally {
      setLoading(false);
    }
  }, [id, currentUser, getConnection]);

  useEffect(() => {
    fetchJobAndConnectionData();
  }, [fetchJobAndConnectionData]);

  const handleConnectionRequest = async () => {
    if (currentUser && jobData?.companyId) {
      await createConnection(currentUser.uid, jobData.companyId, "candidate");
      setConnectionStatus("pending");
    }
  };

  const renderConnectionButton = () => {
    switch (connectionStatus) {
      case "pending":
        return (
          <button className="button-pill my-3" disabled>
            Connection Pending
          </button>
        );
      case "accepted":
        return (
          <button className="button-pill my-3 btn-success" disabled>
            You're Connected
          </button>
        );
      case "rejected":
        return null;
      default:
        return (
          <button className="button-pill my-3" onClick={handleConnectionRequest}>
            Connect with Company
          </button>
        );
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!jobData) {
    return <div className="mt-5">Job not found...</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section className="job-listing public-sans-regular">
        <div className="d-flex justify-content-between">
          <button className="button-pill mb-4" onClick={handleBack}>
            Go Back
          </button>
          {renderConnectionButton()}
        </div>

        {loading && <LoadingSpinner />}
        <div className="card p-4 d-flex flex-col gap-2 mb-2">
          <h3>{jobData.jobTitle}</h3>
          <div className="job-metadata d-flex flex-row justify-content-start gap-3">
            <div className="d-flex gap-1">
              <i className="bi-geo-alt"></i>
              <span>{jobData.location}</span>
            </div>
            <div className="d-flex gap-1">
              <i className="bi-wallet"></i>
              {jobData.salaryMin === jobData.salaryMax ? (
                <span>${jobData.salaryMax}</span>
              ) : (
                <span>
                  ${jobData.salaryMin} - ${jobData.salaryMax}
                </span>
              )}
            </div>
          </div>
          {jobData.jobDescription && <p>{jobData.jobDescription}</p>}

          <h4 className="mt-3">About {companyData.name}</h4>
          {companyData.description && <p>{companyData.description}</p>}
          <Link to={`/companies/${jobData.companyId}`} className="mb-3">
            <p>View Company Profile</p>
          </Link>

          {jobData.jobResponsibilities && (
            <>
              <h4>Job Responsibilities</h4>
              <FormattedText htmlString={jobData.jobResponsibilities} />
            </>
          )}

          <h4>Qualifications</h4>
          {jobData.education && <p>{jobData.education}</p>}
          {jobData.licenses && <p>{jobData.licenses}</p>}

          {jobData.experience && (
            <>
              <h5>Experience</h5>
              <FormattedText htmlString={jobData.experience} />
            </>
          )}
          {jobData.skills && (
            <>
              <h5>Skills</h5>
              <FormattedText htmlString={jobData.skills} />
            </>
          )}

          <h4>Compensation & Benefits</h4>
          {jobData.compensationInfo && <p>{jobData.compensationInfo}</p>}
          {jobData.benefits && (
            <>
              <h5>Benefits</h5>
              <FormattedText htmlString={jobData.benefits} />
            </>
          )}

          {jobData.schedule && (
            <>
              <h4>Schedule</h4>
              <p>{jobData.schedule}</p>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default PublicJobPosting;
