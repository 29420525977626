import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase/config";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  where
} from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import Job from "../Job";
import LoadingSpinner from "../LoadingSpinner";

const CandidateJobsList = () => {
  const [loading, setLoading] = useState(false);
  const [likeButtonLoading, setLikeButtonLoading] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [userLikes, setUserLikes] = useState([]);
  const [showSavedJobs, setShowSavedJobs] = useState(
    JSON.parse(localStorage.getItem("showSavedJobs")) || false
  );
  const { currentUser } = useAuth();

  // Function to fetch jobs
  const fetchJobs = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, "jobs"), where("isPublished", "==", true));
      const querySnapshot = await getDocs(q);

      // Fetch job data
      const jobsData = await Promise.all(
        querySnapshot.docs.map(async (jobDoc) => {
          const jobData = jobDoc.data();
          const companyId = jobData.companyId;

          // Fetch the company name using the companyId
          let companyName = "";
          if (companyId) {
            const companyDocRef = doc(db, "companies", companyId);
            const companyDoc = await getDoc(companyDocRef);

            if (companyDoc.exists()) {
              companyName = companyDoc.data().name;
            }
          }

          return {
            id: jobDoc.id,
            ...jobData,
            companyName,
            companyId
          };
        })
      );

      setJobs(jobsData);
    } catch (error) {
      console.error("error fetching jobs:" + error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch user likes
  const fetchUserLikes = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserLikes(userData.likedJobs || []);
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    }
  };

  const handleLike = async (jobId) => {
    if (likeButtonLoading.includes(jobId)) return;
    setLikeButtonLoading((prev) => [...prev, jobId]);

    if (!jobId) {
      console.error("Invalid jobId");
      setLikeButtonLoading(false);
      return;
    }

    try {
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      // check if user doc exists and if likedJobs array exists
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const likedJobs = userData.likedJobs || [];

        if (likedJobs.includes(jobId)) {
          await updateDoc(userDocRef, {
            likedJobs: arrayRemove(jobId)
          });
          setUserLikes((prevLikes) => prevLikes.filter((id) => id !== jobId));
        } else {
          await updateDoc(userDocRef, {
            likedJobs: arrayUnion(jobId)
          });
          setUserLikes((prevLikes) => [...prevLikes, jobId]);
        }
      } else {
        console.error("User document does not exist.");
      }
    } catch (error) {
      console.error("Error updating liked jobs: ", error);
    } finally {
      setLikeButtonLoading((prev) => prev.filter((id) => id !== jobId)); // remove job id from loading state
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchUserLikes();
  }, []);

  // Filter jobs based on whether they're saved

  useEffect(() => {
    localStorage.setItem("showSavedJobs", JSON.stringify(showSavedJobs));
  }, [showSavedJobs]);

  const toggleShowSavedJobs = () => {
    setShowSavedJobs((prevShowSavedJobs) => !prevShowSavedJobs);
  };

  const displayedJobs = (
    showSavedJobs ? jobs.filter((job) => userLikes.includes(job.id)) : jobs
  ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <>
      <section className="profile public-sans-regular">
        {loading && <LoadingSpinner />}

        <h3 className="public-sans-bold mb-3 d-flex gap-3">
          <i className="bi-briefcase"></i>
          <span>Available Jobs</span>
        </h3>

        {/* filter toggle section */}
        <div className="filter-section mb-3 fs-6">
          <span className="me-2">Filters:</span>
          <button
            className={`${showSavedJobs ? "button-pill-filled" : "button-pill"}`}
            onClick={toggleShowSavedJobs}>
            Saved Jobs
          </button>
        </div>

        {/* List of Jobs */}
        <div className="jobs-container">
          {displayedJobs && displayedJobs.length > 0 ? (
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
              {displayedJobs.map((job) => {
                const formattedDate = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric"
                }).format(new Date(job.createdAt));
                return (
                  <div className="col" key={job.id}>
                    <Job
                      id={job.id}
                      name={job.jobTitle}
                      company={job.companyName}
                      postDate={formattedDate}
                      description={job.jobDescription}
                      location={job.location}
                      salaryRange={job.salaryRange}
                      companyId={job.companyId}
                      handleLike={handleLike}
                      liked={userLikes.includes(job.id)}
                      likeButtonLoading={likeButtonLoading.includes(job.id)}
                      isFullTime={job.isFullTime}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
              <div className="col">
                <div className="card no-jobs-card">
                  <div className="card-body">
                    <h5 className="card-title">No saved jobs!</h5>
                    <div className="card-text">
                      You haven't saved any jobs yet! Click the Save Job button on jobs you like so
                      you can connect when you're ready.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CandidateJobsList;
