import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../context/AuthContext";

import "./Dashboard.css";
import "../Fonts.css";

import CompanyHome from "../components/company/CompanyHome";
import CandidateHome from "../components/candidate/CandidateHome";
import LoadingSpinner from "../components/LoadingSpinner";

//for testing - menu option changes from candidate to company

const Dashboard = () => {
  const { currentUser, logout } = useAuth();
  const [userType, setUserType] = useState(null);
  const [userId, setUserId] = useState("");
  const [userCompanyId, setUserCompanyId] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // check if user is not authenticated and redirect to home

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          // If there's no user, redirect to login
          setIsLoggedIn(false);
          navigate("/");
          return;
        }

        // Fetch user data from Firestore
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserId(user.uid); // sets the userId for future use
          setUserCompanyId(userData.companyId); // sets companyId for future use
          setUserType(userData.userType); // maybe this is removed?
          setLoading(false);
        } else {
          console.log("No such user!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    //Check if the currentUser exists, then fetch the data
    if (currentUser) {
      fetchUserData();
    } else {
      navigate("/"); // Redirect if no user
    }
  }, [currentUser, navigate]);

  // handle logout
  const handleLogout = async () => {
    try {
      await logout();
      console.log("user logged out");
    } catch (error) {
      console.error("error logging out:", error);
    }
  };

  // Show loading spinner while data is being fetched
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="dashboard-container">
      {userCompanyId ? (
        <CompanyHome currentUser={currentUser} userId={userId} companyId={userCompanyId} />
      ) : (
        <>
          <CandidateHome currentUser={currentUser} userId={userId} />
        </>
      )}
    </div>
  );
};

export default Dashboard;
