import React from "react";
import ConnectionItem from "./ConnectionItem";

const AcceptedConnections = ({ connections, isCompany }) => {
  return (
    <>
      {!connections.length && <p>No pending requests.</p>}
      {connections.map((connection) => {
        const formattedDate = connection.createdAt
          ? new Date(connection.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            })
          : "Unknown Date";

        return (
          <ConnectionItem
            key={connection.id}
            connectionId={connection.id}
            date={formattedDate}
            status={connection.status}
            profTitle={connection.profTitle}
            name={connection.name}
            firstName={connection.firstName} //Legacy db elements, can be phased out
            isReceived
            candidateId={connection.candidateId}
            companyId={connection.companyId}
            initiator={connection.initiator}
            companyName={connection.companyName}
          />
        );
      })}
    </>
  );
};

export default AcceptedConnections;
