import { useState } from "react";
import Navbar from "../Navbar";
import CompanyJobsList from "./CompanyJobsList";
import CompanyProfile from "./CompanyProfile";
import CompanyCandidates from "./CompanyCandidates";
import AddJobForm from "./AddJobForm";
import "./CompanyDashboard.css";

const CompanyHome = ({ currentUser, userId, companyId }) => {
  const [activeComponent, setActiveComponent] = useState("jobs");
  const handleMenuClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <div>
      <Navbar onMenuClick={handleMenuClick} companyId={companyId} />
      <div className="dashboard-container">
        {activeComponent === "jobs" && (
          <CompanyJobsList companyId={companyId} onClick={handleMenuClick} />
        )}
        {activeComponent === "candidates" && <CompanyCandidates />}
        {activeComponent === "profile" && (
          <CompanyProfile currentUser={currentUser} userId={userId} companyId={companyId} />
        )}
        {activeComponent === "addJob" && (
          <AddJobForm companyId={companyId} onSave={handleMenuClick} />
        )}
      </div>
    </div>
  );
};

export default CompanyHome;
