import React from "react";
import { Link } from "react-router-dom";
import "./Job.css";

const Job = ({
  id,
  name,
  company,
  location,
  postDate,
  companyId,
  handleLike,
  liked,
  likeButtonLoading,
  isFullTime,
  isDisplayedOnProfile
}) => {
  return (
    <div className="card public-sans-regular shadow h-100">
      <div className="card-body d-flex flex-column justify-content-between px-4">
        <div>
          <div className="card-heading d-flex flex-row justify-content-between align-items-top gap-3">
            <h5 className="card-title">
              {name} {isFullTime && `(${isFullTime})`}
            </h5>
            <Link to={`/jobs/${id}`}>
              <i className="bi-box-arrow-up-right"></i>
            </Link>
          </div>
          {!isDisplayedOnProfile && (
            <h6 className="card-subtitle text-body-secondary public-sans-light mb-2">
              Posted by <Link to={`/companies/${companyId}`}>{company}</Link>
            </h6>
          )}
          <p className="card-text">Location: {location}</p>
        </div>
        {!isDisplayedOnProfile && (
          <div className="job-icon-button-container">
            {likeButtonLoading ? (
              <button className="job-icon-button shadow" disabled>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">
                  Loading ...
                </span>
              </button>
            ) : (
              <button
                className={`job-icon-button shadow ${liked ? "btn-selected" : ""}`}
                onClick={() => handleLike(id)}>
                {liked && (
                  <>
                    <i className="bi-bookmark-heart-fill"></i>
                    <span>Saved</span>
                  </>
                )}
                {!liked && (
                  <>
                    <i className="bi-bookmark-heart"></i>
                    <span>Save Job</span>
                  </>
                )}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="card-footer small fst-italic bg-medis-teal">Posted on {postDate} </div>
    </div>
  );
};

export default Job;
