{
  /* Design Inspired by: https://www.landingfolio.com/inspiration/post/otter-1?category=landing-page */
}

import { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase/config"; // Ensure Firebase is set up
import { collection, addDoc } from "firebase/firestore";
import LoggedOutMenu from "./LoggedOutMenu";
import "./LoggedOutHome.css";
import dentistPic from "/for-dentists.jpg";
import forPractices from "/for-practices.jpg";
import Modal from "./Modal";

const LoggedOutHome = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });

  const toggleContactModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);
    try {
      await addDoc(collection(db, "investors"), formData);
      alert("Thank you for your interest! We will be in touch soon.");
      setFormData({ name: "", email: "", message: "" });
      toggleContactModal();
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal title="Invest in Medis" onClose={toggleContactModal}>
          <p>
            If you would like more information about how to invest in Medis, please complete the
            form below.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-2">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name}"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="5"
                value={formData.message}
                onChange={handleChange}
                required></textarea>
            </div>
            <button className="button-pill" type="submit">
              Submit
            </button>
          </form>
        </Modal>
      )}
      <LoggedOutMenu />

      {/* Hero Section */}
      <div className="home-hero">
        <div className="container my-sm-5 box d-flex justify-content-center align-items-center">
          <div className="hero-content-container text-center d-flex flex-column gap-3">
            <h1 className="display-4">The only recruiting platform purpose-built for dentists</h1>
            <p className="lead">
              Medis connects aspiring dentists with in-demand practices to create a better hiring
              experience for everyone
            </p>
            <Link to="/create-account">
              <button className="button-pill mx-auto px-5 py-2">Sign Up</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Why Medis Section */}

      <div className="why-medis dentists">
        <div className="container my-5 d-flex flex-column">
          <div className="why-text-section mx-auto text-center">
            <h2 className="small text-uppercase my-4">Why Medis?</h2>
            <h3 className="display-6 my-4">
              Medis is the first job search platform built for dentistry
            </h3>
            <p className="lead">
              We make it easier to find careers and candidates by tailoring the experience for you,
              not the rest of the job market
            </p>
          </div>
          <div className="for-section d-flex flex-row justify-content-between align-items-top gap-4 mt-5">
            <div className="for-item text-center">
              <div
                id="dentists"
                className="section-image-container mx-auto d-flex align-items-center mb-5"
                style={{ width: "315px", height: "315px" }}>
                <img
                  className="mx-auto my-auto"
                  src={dentistPic}
                  alt=""
                  style={{ width: "285px" }}
                />
              </div>

              <h2 className="small text-uppercase">For Dentists</h2>
              <p>
                Discover your perfect dental career match. Medis connects you with top dental
                offices, offering opportunities tailored to your skills and experience
              </p>
              <Link to="/create-account?type=individual">
                <button className="button-pill">Sign Up Now</button>
              </Link>
            </div>
            <div className="for-item text-center">
              <div
                id="providers"
                className="section-image-container mx-auto d-flex align-items-center mb-5"
                style={{ width: "315px", height: "315px" }}>
                <img
                  className="mx-auto my-auto"
                  src={forPractices}
                  alt=""
                  style={{ width: "285px" }}
                />
              </div>
              <h2 className="small text-uppercase">For Providers</h2>
              <p>
                Unlock high-performing dentists with Medis, designed for practices like yours. Find
                qualified candidates faster, tailored to your unique hiring needs and requirements
              </p>
              <Link to="/create-account?type=company">
                <button className="button-pill">Sign Up Now</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* 
      {/* For Dentists Section */}

      {/* <div className="home-dentists">
        <div className="container my-5 d-flex justify-content-between align-items-center">
          <div className="text-section w-50">
            <h2 className="small text-uppercase">Medis for Dentists</h2>
            <h3 className="display-6">
              We help aspirational dentists find life-long careers.
            </h3>
          </div>
          <div className="image-section w-50 d-flex justify-content-end">
            <div className="section-image-container">
              <img
                className="mx-auto"
                src={dentistPic}
                alt=""
                style={{ width: "350px" }}
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <section className="hero-section">
        <div className="card">
          <h1>Hiring as easy as black and white</h1>
          <h4>
            Medis matches dentists and practices to make the world a better
            place.
          </h4>
          <button>Sign Up</button>
        </div>
      </section> */}
      {/* <section>
        <h2>How it works</h2>
        <h3>Some subheadline about how it works</h3>
        <ul>
          <li>Sign up</li>
          <li>Post jobs</li>
          <li>Invite candidates to apply</li>
        </ul>
      </section> */}

      <footer className="mt-5 py-5 background-teal">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <span className="">© 2025 Medis Jobs LLC</span>
            <div className="d-flex justify-content-end gap-3">
              <Link to="mailto:info@medisjobs.com">Contact</Link>
              <Link onClick={toggleContactModal}>Invest in Medis</Link>
              {/* <Link>Privacy Policy</Link>
              <Link>Terms & Conditions</Link> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LoggedOutHome;
