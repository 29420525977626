import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useCompany } from "../../context/CompanyContext";
import { useConnections } from "../../context/ConnectionsContext";
import AllCandidatesList from "./AllCandidatesList";
import SentConnections from "../SentConnections";
import ReceivedConnections from "../ReceivedConnections";
import "./CompanyCandidates.css";

const CompanyCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savedCandidates, setSavedCandidates] = useState([]);
  const [buttonLoading, setButtonLoading] = useState({});
  const [hasActiveJobs, setHasActiveJobs] = useState(false);
  const [displayActiveLink, setDisplayActiveLink] = useState("");
  const [sentRequests, setSentRequests] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [acceptedConnections, setAcceptedConnections] = useState([]);
  const { companyId, currentUser } = useCompany();
  const { getAllConnectionsForCandidate, getAllConnectionsForCompany } = useConnections();

  const fetchCandidates = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("companyId", "==", null),
        where("profTitle", "!=", null)
      );
      const querySnapshot = await getDocs(q);

      const candidatesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      //setCandidates(candidatesData);
      console.log(candidatesData);
      return candidatesData;
    } catch (error) {
      console.error("error fetching candidates:" + error);
      return [];
    }
  };

  useEffect(() => {
    const fetchConnections = async () => {
      if (!currentUser) {
        console.error("User is not logged in.");
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        let allConnections;

        // If the user has a companyId, then fetch connections by their comapnyId
        if (companyId) {
          allConnections = await getAllConnectionsForCompany(companyId);
        } else {
          allConnections = await getAllConnectionsForCandidate(currentUser.uid);
        }

        setSentRequests(allConnections.pendingSent);
        setReceivedRequests(allConnections.pendingReceived);
        setAcceptedConnections(allConnections.accepted);
      } catch (error) {
        console.error("Error fetching connections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConnections();
  }, [currentUser, companyId, getAllConnectionsForCompany, getAllConnectionsForCandidate]);

  const fetchSavedCandidates = async () => {
    try {
      const candidateDocRef = doc(db, "companies", companyId);
      const candidateDoc = await getDoc(candidateDocRef);
      if (candidateDoc.exists()) {
        const candidateData = candidateDoc.data();
        //setSavedCandidates(candidateData.savedCandidates || []);
        return candidateData.savedCandidates || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("error fetching saved candidates: ", error);
      return [];
    }
  };

  // Check if the company has active job postings
  const checkActiveJobs = async () => {
    try {
      const jobsRef = collection(db, "jobs");
      const activeJobsQuery = query(
        jobsRef,
        where("companyId", "==", companyId),
        where("isPublished", "==", true)
      );
      const activeJobsSnapshot = await getDocs(activeJobsQuery);
      setHasActiveJobs(!activeJobsSnapshot.empty); // true if there are active jobs
    } catch (error) {
      console.error("error checking active jobs: ", error);
    }
  };

  useEffect(() => {
    // we'll use Promise.all to fetch candidates and saved candidates
    const fetchData = async () => {
      try {
        const [candidatesData, savedCandidatesData] = await Promise.all([
          fetchCandidates(),
          fetchSavedCandidates()
        ]);
        setCandidates(candidatesData);
        setSavedCandidates(savedCandidatesData);
        await checkActiveJobs();
      } catch (error) {
        console.error("error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [companyId]);

  const handleSaveCandidate = async (candidateId) => {
    setButtonLoading((prevState) => ({ ...prevState, [candidateId]: true }));
    try {
      const companyDocRef = doc(db, "companies", companyId);
      //if candidate is already saved, remove from savedCandidates
      if (savedCandidates.includes(candidateId)) {
        await updateDoc(companyDocRef, {
          savedCandidates: arrayRemove(candidateId)
        });
        setSavedCandidates((prev) => prev.filter((id) => id !== candidateId));
      } else {
        // add candidate to savedCandidates array
        await updateDoc(companyDocRef, {
          savedCandidates: arrayUnion(candidateId)
        });
        setSavedCandidates((prev) => [...prev, candidateId]);
      }
    } catch (error) {
      console.error("error saving candidate: ", error);
    } finally {
      setButtonLoading((prevState) => ({ ...prevState, [candidateId]: false }));
    }
  };

  const handleLinkClick = (link) => {
    setDisplayActiveLink(link);
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed((prev) => !prev);
  };

  // Only show candidate lists if company has posted a job
  // Split candidate lists into:
  // 1. All Candidates - need to identify who's been connected with
  // 2. Mutual connections - those with whom you have an accepted connectino
  // 3. Sent connection requests - those to whom you've sent requests, but are still pending
  // 4. Received requests - those who have requested to connect with you

  return (
    <>
      <section className="connections public-sans-regular">
        <h3 className="public-sans-bold mb-3 d-flex gap-3">
          <i className="bi-chat-dots"></i>
          <span>Candidates</span>
        </h3>
        <div className="card p-4 d-flex flex-col gap-2 mb-2">
          {/* Nav links to filter candidates */}
          <nav className="nav connections-nav mb-3">
            <Link
              onClick={() => handleLinkClick("")}
              className={`nav-link ${displayActiveLink === "" ? "active" : ""}`}>
              All Candidates
            </Link>
            <Link
              onClick={() => handleLinkClick("accepted")}
              className={`nav-link ${displayActiveLink === "accepted" ? "active" : ""}`}>
              Connections
            </Link>
            <Link
              onClick={() => handleLinkClick("received")}
              className={`nav-link ${displayActiveLink === "received" ? "active" : ""}`}>
              Received
            </Link>
            <Link
              onClick={() => handleLinkClick("sent")}
              className={`nav-link ${displayActiveLink === "sent" ? "active" : ""}`}>
              Sent
            </Link>
          </nav>
          {/* Display candidates based on the active link */}
          {displayActiveLink === "" && (
            <>
              {loading && (
                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="card-title placeholder-glow mb-3">
                      <span className="placeholder col-2"></span>
                    </h5>
                    <p className="card-text placeholder-glow d-flex flex-column gap-1">
                      <span className="placeholder col-5"></span>
                      <span className="col-3 placeholder"></span>
                    </p>
                  </div>
                </div>
              )}

              {!loading && candidates.length === 0 && (
                <p className="public-sans-regular">No candidates yet.</p>
              )}
              {/* replace with AllCandidatesList.jsx component */}
              {!loading && candidates.length > 0 && (
                <>
                  <AllCandidatesList candidates={candidates} hasActiveJobs={hasActiveJobs} />
                  <hr></hr>
                  {candidates.map((candidate) => (
                    <div className="card mb-3" key={candidate.uid}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h5 className="card-title">
                            {hasActiveJobs ? candidate.firstName : candidate.profTitle}
                          </h5>
                          {hasActiveJobs && (
                            <div
                              className="d-flex gap-1 align-items-center"
                              onClick={() => handleSaveCandidate(candidate.id)}
                              style={{ cursor: "pointer" }}>
                              {buttonLoading[candidate.id] ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    aria-hidden="true"></span>
                                  <span role="status" className="visually-hidden">
                                    Loading...
                                  </span>
                                </>
                              ) : savedCandidates.includes(candidate.id) ? (
                                <>
                                  <i className="bi-bookmark-heart-fill"></i>
                                  <span>Saved</span>
                                </>
                              ) : (
                                <>
                                  <i className="bi-bookmark"></i>
                                  <span>Save</span>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <p className="card-text">Location: {candidate.location}</p>
                        {hasActiveJobs ? (
                          <Link to={`/candidates/${candidate.id}`}>View Full Profile</Link>
                        ) : (
                          <Link to="/company/jobs">To view candidate details, publish a job.</Link>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
          {displayActiveLink === "received" && (
            <ReceivedConnections connections={receivedRequests} isCompany={!!companyId} />
          )}
          {displayActiveLink === "sent" && (
            <SentConnections connections={sentRequests} isCompany={!!companyId} />
          )}
          {displayActiveLink === "accepted" && <div>Here are your mutual connections</div>}
        </div>
      </section>
      <section id="candidates" className="candidates">
        {/* only show saved candidates section if company has active job posting*/}
        {savedCandidates.length > 0 && hasActiveJobs && (
          <div>
            <h3 className="public-sans-bold mb-4">Saved Candidates</h3>
            {candidates
              .filter((candidate) => savedCandidates.includes(candidate.id))
              .map((candidate) => (
                <div className="card mb-3" key={candidate.id}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h5 className="card-title">{candidate.firstName}</h5>
                      <div
                        className="d-flex gap-1 align-items center"
                        onClick={() => handleSaveCandidate(candidate.id)}
                        style={{ cursor: "pointer" }}>
                        {buttonLoading[candidate.id] ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              aria-hidden="true"></span>
                            <span role="status" className="visually-hidden">
                              Loading...
                            </span>
                          </>
                        ) : (
                          <>
                            <i className="bi-bookmark-heart-fill"></i>
                            <span>Saved</span>
                          </>
                        )}
                      </div>
                    </div>
                    <p className="card-text">Location: {candidate.location}</p>
                    <Link to={`/candidates/${candidate.id}`}>View Full Profile</Link>
                  </div>
                </div>
              ))}
          </div>
        )}

        <h3 className="public-sans-bold mb-4">Candidates</h3>

        {loading && (
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title placeholder-glow mb-3">
                <span className="placeholder col-2"></span>
              </h5>
              <p className="card-text placeholder-glow d-flex flex-column gap-1">
                <span className="placeholder col-5"></span>
                <span className="col-3 placeholder"></span>
              </p>
            </div>
          </div>
        )}

        {!loading && candidates.length === 0 && (
          <p className="public-sans-regular">No candidates yet.</p>
        )}

        {candidates.length > 0 && (
          <>
            {candidates.map((candidate) => (
              <div className="card mb-3" key={candidate.uid}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h5 className="card-title">
                      {hasActiveJobs ? candidate.firstName : candidate.profTitle}
                    </h5>
                    {hasActiveJobs && (
                      <div
                        className="d-flex gap-1 align-items-center"
                        onClick={() => handleSaveCandidate(candidate.id)}
                        style={{ cursor: "pointer" }}>
                        {buttonLoading[candidate.id] ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              aria-hidden="true"></span>
                            <span role="status" className="visually-hidden">
                              Loading...
                            </span>
                          </>
                        ) : savedCandidates.includes(candidate.id) ? (
                          <>
                            <i className="bi-bookmark-heart-fill"></i>
                            <span>Saved</span>
                          </>
                        ) : (
                          <>
                            <i className="bi-bookmark"></i>
                            <span>Save</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <p className="card-text">Location: {candidate.location}</p>
                  {hasActiveJobs ? (
                    <Link to={`/candidates/${candidate.id}`}>View Full Profile</Link>
                  ) : (
                    <Link to="/company/jobs">To view candidate details, publish a job.</Link>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </section>
    </>
  );
};

export default CompanyCandidates;
