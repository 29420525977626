import { useAuth } from "./context/AuthContext";
import { CompanyProvider } from "./context/CompanyContext"; // Add this
import { ConnectionsProvider } from "./context/ConnectionsContext";

import Layout from "./components/Layout";
import LoggedOutHome from "./components/LoggedOutHome";
import AuthRedirect from "./AuthRedirect";
import LoadingSpinner from "./components/LoadingSpinner";
import PasswordGate from "./components/PasswordGate";

import "./App.css";

function App() {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!currentUser) {
    return <LoggedOutHome />;
  }

  // if (!currentUser) {
  //   if (process.env.NODE_ENV === "production") {
  //     return (
  //       <PasswordGate>
  //         <LoggedOutHome />
  //       </PasswordGate>
  //     );
  //   } else {
  //     return <LoggedOutHome />;
  //   }
  // }

  return (
    <CompanyProvider>
      <ConnectionsProvider>
        {/* <AuthRedirect /> */}
        <Layout currentUser={currentUser} />
      </ConnectionsProvider>
    </CompanyProvider>
  ); //Logged-in state
}

export default App;
