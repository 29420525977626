import { useEffect, useState } from "react";
import { collection, query, where, getDocs, addDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import LoadingSpinner from "./LoadingSpinner";

const CallToPay = ({ currentUser }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      const productsRef = collection(db, "products");
      const productsQuery = query(productsRef, where("active", "==", true));
      const querySnapshot = await getDocs(productsQuery);

      const productsData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const product = doc.data();
          const priceSnap = await getDocs(collection(doc.ref, "prices"));

          // Filter prices to includes only with type recurring
          const prices = priceSnap.docs
            .map((priceDoc) => ({
              id: priceDoc.id,
              ...priceDoc.data()
            }))
            .filter((price) => price.type === "recurring" && price.active === true);

          // Only include products that have at least one recurring price
          if (prices.length > 0) {
            return {
              id: doc.id,
              ...product,
              prices
            };
          }
          return null;
        })
      );

      // filter out null values (products without recurring prices)
      setProducts(productsData.filter((product) => product !== null));
    } catch (error) {
      console.error("error fetching products: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSubscribe = async (priceId, priceInterval) => {
    setButtonLoading((prevState) => ({ ...prevState, [priceId]: true }));
    try {
      const checkoutSessionsRef = collection(db, "users", currentUser.uid, "checkout_sessions");

      // Construct session data dynamically
      const sessionData = {
        price: priceId,
        success_url: `${window.location.origin}/company/profile?payment_status=success&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/company/profile`
      };

      // Add trial period days if the price interval is 'year'
      if (priceInterval === "year") {
        sessionData.trial_period_days = 60;
      }

      // Add checkout session to firestore
      const docRef = await addDoc(checkoutSessionsRef, sessionData);

      // create  snapshot listener to the document
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const data = snap.data();
        if (!data) {
          console.error("No data found in snapshot.");
          return;
        }

        const { error, url } = data;
        if (error) {
          alert(`An error occurred: ${error.message}`);
          console.error("stripe checkout error: ", error);
          setButtonLoading((prevState) => ({ ...prevState, [priceId]: false })); // Stop loading for specific price
          unsubscribe();
          return;
        }

        if (url) {
          unsubscribe();
          window.location.assign(url); // Redirect to Stripe checkout
        }
      });
    } catch (error) {
      console.error("error during stripe checkout session creation: ", error);
      setButtonLoading((prevState) => ({ ...prevState, [priceId]: false })); // Stop loading if an error occurs
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="px-3 small">
          <hr className="mt-0" />
          <p className="">
            To post jobs and view the types of candidates available, you'll need to subscribe. All
            subscription tiers include:
          </p>
          <ul>
            <li>Unlimited paid job postings</li>
            <li>Access to candidate titles and locations</li>
            <li>Ability to contact candidates (with an active paid job posting)</li>
          </ul>
          <div className="row">
            {products.map((product) => (
              <div key={product.id} className="mb-3">
                {/* <h5 className="card-title">{product.name}</h5> */}
                <p className="fw-bold">{product.description}</p>
                <div className="row">
                  {product.prices.map((price) => {
                    const displayPrice =
                      price.interval === "year"
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(price.unit_amount / 100 / 12)
                        : new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                          }).format(price.unit_amount / 100);
                    const displayInterval = price.interval === "year" ? "month" : price.interval;
                    return (
                      <div className="col-md-6" key={price.id}>
                        <div className="card">
                          <div className="card-body">
                            <h6 className="card-title">{price.description}</h6>
                            <ul className="">
                              <li>
                                {displayPrice} per {displayInterval},{" "}
                                <strong>billed each {price.interval}</strong>
                              </li>
                              <li>
                                {price.interval == "year"
                                  ? "Includes 2-month free trial"
                                  : "Cancel any time"}
                              </li>
                            </ul>

                            <button
                              className="button-pill"
                              onClick={() => handleSubscribe(price.id, price.interval)}
                              disabled={buttonLoading[price.id]} // Disable button if loading
                            >
                              {buttonLoading[price.id] ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm me-2"
                                    aria-hidden="true"></span>
                                  <span role="status">Loading...</span>
                                </>
                              ) : (
                                `Choose ${price.description}`
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CallToPay;
