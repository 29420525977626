import { useState } from "react";
import { Link } from "react-router-dom";
import "./CompanyOnboarding.css";
import onboardingData from "./CompanyOnboardingData";
import OnboardingSub from "./OnboardingSub";
import OnboardingCompanyName from "./OnboardingCompanyName";
import OnboardingLogo from "./OnboardingLogo";
import OnboardingDescription from "./OnboardingDescription";
import OnboardingWebsite from "./OnboardingWebsite";
import OnboardingLocation from "./OnboardingLocation";

const CompanyOnboarding = ({
  currentUser,
  companyData,
  hasActiveSubscription,
  handleFileChange,
  triggerFileInput,
  uploading,
  onboardingProgress,
  saveCompanyDescription,
  saveCompanyWebsite,
  saveLocation
}) => {
  // Create some state to track the elements of onboarding that have been completed

  const [progress, setProgress] = useState(companyData.onboardingCompleted || [1]);

  return (
    <section>
      <div className="onboarding-header text-center mb-4">
        <h3 className="text-center fw-bold">Let's Build Your Profile</h3>
        <p className="fw-lighter">
          Finding the best talent requires an attractive company profile
          <br />
          Complete each section to unlock every Medis feature
        </p>
      </div>
      {onboardingData && (
        <div className="card w-75 my-0 mx-auto">
          <div className="card-body">
            <OnboardingCompanyName progress={progress} />
            <OnboardingSub
              currentUser={currentUser}
              progress={progress}
              onboardingProgress={onboardingProgress}
              hasActiveSubscription={hasActiveSubscription}
            />
            <OnboardingLogo
              progress={progress}
              handleFileChange={handleFileChange}
              triggerFileInput={triggerFileInput}
              uploading={uploading}
              onboardingProgress={onboardingProgress}
              companyData={companyData}
            />
            <OnboardingDescription
              companyData={companyData}
              onboardingProgress={onboardingProgress}
              saveCompanyDescription={saveCompanyDescription}
            />
            <OnboardingWebsite
              companyData={companyData}
              onboardingProgress={onboardingProgress}
              saveCompanyWebsite={saveCompanyWebsite}
            />
            <OnboardingLocation
              saveLocation={saveLocation}
              companyData={companyData}
              onboardingProgress={onboardingProgress}
            />

            {/*             
            {onboardingData.map((data) => (
              <div
                className={`card mb-3 ${Math.max(...progress) < data.order - 1 && "disabled-card"} ${companyData.onboardingCompleted.includes(data.order) ? "theme-dark" : ""}`}>
                <div
                  key={data.order}
                  className="d-flex justify-content-between align-items-center py-2 px-3">
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    <i className={data.icon} style={{ fontSize: "2rem" }}></i>
                    <div>
                      <h6 className="mb-0">{data.title}</h6>
                      <span className="small fw-lighter">{data.description}</span>
                    </div>
                  </div>
                  {progress.includes(data.order) ? (
                    <span className="small">All Done!</span>
                  ) : (
                    <button
                      className="button-pill small"
                      disabled={Math.max(...progress) < data.order - 1}>
                      {data.buttonTitle}
                    </button>
                  )}
                </div>
              </div>
            ))} */}
          </div>
        </div>
      )}
    </section>
  );
};

export default CompanyOnboarding;
