import React from "react";
import {
  Editor,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnUndo,
  EditorProvider,
  Toolbar
} from "react-simple-wysiwyg";

const FormattingEditor = ({ value, onChange, name, id, placeholder }) => {
  return (
    <EditorProvider>
      <Editor
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        placeholder={placeholder}
        containerProps={{ style: { minHeight: "175px", resize: "vertical" } }}>
        <Toolbar>
          <BtnUndo />
          <BtnRedo />
          <BtnBold />
          <BtnItalic />
          <BtnBulletList />
          <BtnNumberedList />
          <BtnClearFormatting />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};

export default FormattingEditor;
