import { useState } from "react";

const ResetPassword = ({ handlePasswordReset, loading, message, error }) => {
  const [password, setPassword] = useState("");

  const handleNewPassword = (e) => {
    e.preventDefault();
    handlePasswordReset(password);
  };
  return (
    <>
      <form onSubmit={handleNewPassword} className="login-form">
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder=""
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <label htmlFor="email" className="public-sans-regular">
            New Password
          </label>
        </div>
        {loading ? (
          <button className="button-large w-100" disabled>
            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
            <span role="status">Loading...</span>
          </button>
        ) : (
          <button className="button-large w-100 public-sans-regular" type="submit">
            Submit New Password
          </button>
        )}
      </form>
      {error && <p className="text-danger">{error}</p>}
    </>
  );
};

export default ResetPassword;
