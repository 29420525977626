// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";
import { logEvent } from "firebase/analytics";

import { firebaseProdConfig } from "./config.prod";
import { firebaseNonProdConfig } from "./config.non-prod";

// Firebase configuration
let firebaseConfig;

firebaseConfig = process.env.NODE_ENV === "production" ? firebaseProdConfig : firebaseNonProdConfig;
console.log("Firebase config", firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics only in browser environments (prevents SSR issues)
let analytics;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);

export { db, auth, storage, remoteConfig, analytics, logEvent };
