import { useState } from "react";
import Card from "../Card";

const CandidatePreferences = ({ locationPrefs, addLocation, deleteLocation }) => {
  const [newLocation, setNewLocation] = useState("");

  const handleAddLocation = () => {
    // Check first for empty string
    if (newLocation.trim() !== "") {
      addLocation(newLocation.trim());
      setNewLocation(""); //Clear input field
    } else {
      alert("Enter a location before submitting");
    }
  };

  return (
    <Card>
      <h4>Job Preferences</h4>
      <hr className="mt-0" />
      <h5>Preferred Locations</h5>
      {locationPrefs &&
        locationPrefs.length > 0 &&
        locationPrefs.map((pref, index) => (
          <div key={index} className="mb-2 d-flex flex-sm-nowrap gap-3">
            <input type="text" className="form-control" value={pref} disabled />
            <button
              className="button-pill small"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => deleteLocation(pref)}>
              <i className="bi-trash"></i>
            </button>
          </div>
        ))}

      <div className="mb-2 d-flex flex-sm-nowrap gap-3">
        <input
          type="text"
          className="form-control"
          placeholder="ex. Houston, TX"
          value={newLocation}
          onChange={(e) => setNewLocation(e.target.value)}
        />
        <button
          className="button-pill small"
          onClick={handleAddLocation}
          style={{ whiteSpace: "nowrap" }}>
          Add Location
        </button>
      </div>
    </Card>
  );
};

export default CandidatePreferences;
