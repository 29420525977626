import { useState } from "react";
import { Link } from "react-router-dom";
import AddEducationForm from "./AddEducationForm";
import "../../App.css";

const CandidateEducation = ({ education = [], saveEducation }) => {
  const [isAddingEducation, setIsAddingEducation] = useState(false);

  const toggleEditingEducation = () => {
    setIsAddingEducation((prevState) => !prevState);
  };

  const addEducation = (newEducation) => {
    const updatedEducation = [...education, newEducation];
    saveEducation(updatedEducation);
  };

  return (
    <>
      <div className="card p-4 d-flex flex-col gap-2 mb-2">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Education</h4>

          <button className="button-pill" onClick={toggleEditingEducation}>
            Add Education
          </button>
        </div>
        {education &&
          education.length > 0 &&
          education.map((edu, index) => (
            <div className="education-item mb-2" key={index}>
              <h5>
                {edu.institution}, {edu.startYear} - {edu.endYear}
              </h5>
              <div dangerouslySetInnerHTML={{ __html: edu.additionalContent }} />
            </div>
          ))}
        {isAddingEducation && (
          <AddEducationForm
            toggleEditingEducation={toggleEditingEducation}
            addEducation={addEducation}
            currentEducation={education}
          />
        )}
        {!education && !isAddingEducation && <div>Click Edit to add your education</div>}
      </div>
    </>
  );
};

export default CandidateEducation;
