import { useState, useEffect } from "react";
import { storage, db } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import Card from "../Card";
import { useCompany } from "../../context/CompanyContext";

import "./OfficePhotos.css";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2mb file size

const OfficePhotos = () => {
  const { companyId, companyData } = useCompany();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [officePhotos, setOfficePhotos] = useState([]);

  useEffect(() => {
    const checkAndInitializeOfficePhotos = async () => {
      if (companyData) {
        if (!companyData.officePhotos) {
          await updateDoc(doc(db, "companies", companyId), {
            officePhotos: []
          });
          setOfficePhotos([]);
        } else {
          setOfficePhotos(companyData.officePhotos);
        }
      } else {
        console.log("Company data was not sent from company context");
      }
    };
    if (companyId) {
      checkAndInitializeOfficePhotos();
    }
  }, [companyId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      alert("File is too large! Please upload a file smaller than 2MB.");
      return;
    }

    uploadFile(file);
  };

  const uploadFile = async (file) => {
    const storageRef = ref(storage, `company_office_photos/${companyId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed: ", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await saveFileURL(downloadURL);
        setUploading(false);
      }
    );
  };

  const saveFileURL = async (downloadURL) => {
    try {
      const companyDocRef = doc(db, "companies", companyId);
      await updateDoc(companyDocRef, {
        officePhotos: arrayUnion(downloadURL)
      });
      setOfficePhotos((prevPhotos) => [...prevPhotos, downloadURL]);
    } catch (error) {
      console.error("Error saving file URL: ", error);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("uploadFile").click();
  };

  return (
    <Card>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h4 className="mb-0">Office Photos</h4>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*, video/*"
          style={{ display: "none" }}
          id="uploadFile"
        />
        <label htmlFor="uploadFile">
          <button className="button-pill" type="button" onClick={triggerFileInput}>
            {uploading ? `Uploading ${Math.round(progress)}%` : "Add Photo/Video"}
          </button>
        </label>
      </div>
      <div className="d-flex flex-wrap gap-2 my-2">
        {officePhotos.length > 0 ? (
          officePhotos.map((url, index) =>
            url.includes("video") ? (
              <div className="media-container" key={index}>
                <video src={url} height="150" controls />
              </div>
            ) : (
              <div className="media-container" key={index}>
                <img src={url} alt={`office-photo-${index}`} className="office-image" />
              </div>
            )
          )
        ) : (
          <p>No photos or videos uploaded yet.</p>
        )}
      </div>
    </Card>
  );
};

export default OfficePhotos;
