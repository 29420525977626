import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "./Modal";

const CreateAccountForm = ({ onSubmit, loading, toggleLoading, error }) => {
  //state management for the form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [consent, setConsent] = useState(false);
  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    toggleLoading();
    const formData = { email, password };
    const userType = "individual";
    const success = await onSubmit(formData, userType);
    if (success) {
      setEmail("");
      setPassword("");
      setConsent(false);
    }
  };

  const togglePasswordModal = () => {
    setDisplayPasswordModal((prevState) => !prevState);
  };

  return (
    <div className="form-container my-5">
      {displayPasswordModal && (
        <Modal title="Password requirements" onClose={togglePasswordModal}>
          <p>Your password must be at least 8 characters and include the following:</p>
          <ul>
            <li>Uppercase letter</li>
            <li>Lowercase letter</li>
            <li>Number</li>
            <li>Special character</li>
          </ul>
        </Modal>
      )}
      <form onSubmit={handleFormSubmission}>
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <label htmlFor="email" className="public-sans-regular">
            Email address
          </label>
        </div>
        <div className="input-group">
          <div className="form-floating flex-grow-1">
            <input
              type="password"
              className="form-control public-sans-regular rounded-end"
              id="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
            <label htmlFor="password" className="public-sans-regular">
              Password
            </label>
            <span className="input-group-text bg-transparent border-0 rounded-end">
              <i className="bi-info-circle" onClick={togglePasswordModal}></i>
            </span>
          </div>
        </div>

        <div className="form-check mt-4">
          <input
            type="checkbox"
            className="form-check-input"
            checked={consent}
            id="termsCheckbox"
            onChange={(e) => setConsent(e.target.checked)}
            required
          />
          <label htmlFor="termsCheckbox" className="form-check-label public-sans-regular">
            I accept the Medis <Link to="/">terms and conditions</Link>.
          </label>
        </div>
        <div className="button-container public-sans-regular mt-3">
          {loading && (
            <button className="button-large" disabled>
              <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
              <span role="status">Loading...</span>
            </button>
          )}
          {!loading && (
            <button className="button-large" typeof="submit">
              Create Account
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateAccountForm;
