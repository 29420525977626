import React from "react";
import { Link } from "react-router-dom";

const CandidateResume = ({ handleResumeChange, uploading, progress, resume }) => {
  const triggerFileInput = () => {
    document.getElementById("uploadResume").click();
  };

  return (
    <>
      <div className="card p-4 d-flex flex-col gap-2 mb-2">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Upload Resume</h4>
          <div>
            <input
              type="file"
              onChange={handleResumeChange}
              accept="image/*,application/pdf"
              style={{ display: "none" }}
              id="uploadResume"
            />
            <label htmlFor="uploadResume">
              <button className="button-pill mb-3" type="button" onClick={triggerFileInput}>
                {uploading ? `Uploading ${Math.round(progress)}%` : "Add Resume"}
              </button>
            </label>
          </div>
        </div>
        {resume && (
          <div>
            <Link to={resume} target="_blank">
              Download Resume
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateResume;
