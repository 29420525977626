/* eslint-disable react/prop-types */
import { useState } from "react";
import CallToPay from "../CallToPay";

const OnboardingSub = ({ currentUser, companyData, onboardingProgress, hasActiveSubscription }) => {
  const [showProducts, setShowProducts] = useState(false);

  const toggleShowProducts = () => {
    setShowProducts((prevState) => !prevState);
  };

  // Check if sub step is complete
  const isSubscribed =
    companyData?.onboardingCompleted?.includes(2) ||
    onboardingProgress.includes(2) ||
    hasActiveSubscription;

  return (
    <>
      <div className={`card mb-3 ${isSubscribed && "theme-dark"}`}>
        <div className="d-flex justify-content-between align-items-center py-2 px-3">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <i className="bi-cart-check" style={{ fontSize: "2rem" }}></i>
            <div>
              <h6 className="mb-0">Start your subscription</h6>
              <span className="small fw-lighter">Subscribe to unlock every feature</span>
            </div>
          </div>
          {isSubscribed && (
            <span className="">
              <i className="bi-check-lg" />
            </span>
          )}
          {!isSubscribed && !showProducts && (
            <button className="button-pill small" onClick={toggleShowProducts}>
              Subscribe
            </button>
          )}
          {!isSubscribed && showProducts && (
            <i className="bi-x-circle" onClick={toggleShowProducts}></i>
          )}
        </div>
        {showProducts && <CallToPay currentUser={currentUser} />}
      </div>
    </>
  );
};

export default OnboardingSub;
