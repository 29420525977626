import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";

// Fetch subscription status
export const fetchSubscriptionStatus = async (userId) => {
  try {
    const subscriptionsRef = collection(db, "users", userId, "subscriptions");
    const snapshot = await getDocs(subscriptionsRef);

    let active = false,
      trialing = false,
      endDate = null;

    snapshot.forEach((doc) => {
      const data = doc.data();
      if (data.status === "trialing") {
        trialing = true;
        endDate = data.trial_end;
      } else if (data.status === "active") {
        active = true;
        endDate = data.current_period_end;
      }
    });

    return { active, trialing, endDate };
  } catch (error) {
    console.error("Error fetching subscription status:", error);
    return { active: false, trialing: false, endDate: null };
  }
};

// Fetch published jobs
export const fetchPublishedJobs = async (companyId) => {
  try {
    const jobsRef = collection(db, "jobs");
    const jobsQuery = query(
      jobsRef,
      where("companyId", "==", companyId),
      where("isPublished", "==", true)
    );
    const snapshot = await getDocs(jobsQuery);
    return !snapshot.empty;
  } catch (error) {
    console.error("Error fetching published jobs:", error);
    return false;
  }
};

// Fetch company data
export const fetchCompanyData = async (companyId) => {
  try {
    const companyDocRef = doc(db, "companies", companyId);
    const companyDoc = await getDoc(companyDocRef);
    return companyDoc.exists() ? companyDoc.data() : null;
  } catch (error) {
    console.error("Error fetching company data:", error);
    return null;
  }
};
