import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { storage, db } from "../../firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { useCompany } from "../../context/CompanyContext";
import "./CompanyDescriptionSection.css";
import LoadingSpinner from "../LoadingSpinner";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2mb file size

const CompanyDescriptionSection = ({
  profilePic,
  name,
  description,
  website,
  phone,
  email,
  toggleEditingProfile
}) => {
  const { companyId, companyData } = useCompany();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [logo, setLogo] = useState("");
  const [editingDescription, setEditingDescription] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    website: "",
    phone: "",
    email: ""
  });

  // Check if logo exists
  useEffect(() => {
    const checkAndInitializeLogo = async () => {
      if (!companyData.logo) {
        await updateDoc(doc(db, "companies", companyId), {
          logo: ""
        });
        setLogo("");
      } else {
        setLogo(companyData.logo);
      }
    };
    if (companyId && companyData) {
      checkAndInitializeLogo();
      setFormData({
        name: name || "",
        website: website || "",
        phone: phone || "",
        email: email || ""
      });
    }
  }, [companyId, companyData]);

  // Handle file change and check for size
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > MAX_FILE_SIZE) {
      alert("File is too large! Please upload a file smaller than 2MB.");
      return;
    }

    uploadFile(file);
  };

  // Upload file to storage bucket
  const uploadFile = async (file) => {
    const storageRef = ref(storage, `company_logos/${companyId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed: ", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await saveFileURL(downloadURL);
        setUploading(false);
      }
    );
  };

  // Save logo URL to Firestore and update logo state
  const saveFileURL = async (downloadURL) => {
    try {
      await updateDoc(doc(db, "companies", companyId), {
        logo: downloadURL
      });
      setLogo(downloadURL);
    } catch (error) {
      console.error("Error saving logo file URL: ", error);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("uploadFile").click();
  };

  const toggleEditingDescription = () => {
    setEditingDescription((prevState) => !prevState);
  };

  // Handle form field changes when user edits main profile section
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    console.log(formData);
  };

  // Handle a form submission -> Should push this to parent component
  const handleSubmit = async (e) => {
    e.preventDefault();
    const companyDocRef = doc(db, "companies", companyId);
    try {
      await updateDoc(companyDocRef, {
        name: formData.name,
        website: formData.website,
        phone: formData.phone,
        email: formData.email
      });
      setLoading(false);
      console.log("Company data updated successfull");
    } catch (error) {
      console.error("error udpating company data:", error);
      setLoading(false);
    } finally {
      // toggleSuccessAlert();
      // toggleEditingProfile();
      toggleEditingDescription();
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="card p-4 d-flex flex-col gap-2 mb-2">
        {!editingDescription && (
          <div className="row gx-5">
            <div className="col-sm-2">
              <img src={logo} alt="" style={{ width: "100%" }} />{" "}
            </div>
            <div className="col-sm-10">
              <div className="d-flex justify-content-between align-items-top">
                <h3>{name}</h3>
                <i className="bi-pencil" onClick={toggleEditingDescription}></i>
              </div>
              <div className="d-flex justify-content-start gap-4 align-items-center small">
                <div className="d-flex gap-2 align-items-center">
                  <i className="bi-link-45deg"></i>
                  <Link to={website} className="text-decoration-none" target="_blank">
                    {website}
                  </Link>
                </div>

                <div className="d-flex gap-2 align-items-center">
                  <i className="bi-phone"></i>
                  {phone ? (
                    <span>{phone}</span>
                  ) : (
                    <Link onClick={toggleEditingDescription}>Add company phone number</Link>
                  )}
                </div>

                <div className="d-flex gap-2 align-items-center">
                  <i className="bi-envelope"></i>
                  {email ? (
                    <span>{email}</span>
                  ) : (
                    <Link onClick={toggleEditingDescription}>Add company email</Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {editingDescription && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h5>Edit Company Information</h5>
              <i className="bi-x-circle" onClick={toggleEditingDescription}></i>
            </div>
            <div className="d-flex flex-row gap-3 mb-3">
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/gif"
                id="uploadFile"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <img src={logo} style={{ width: "150px" }} />
              <Link
                className="text-decoration-none small d-flex gap-2 align-items-center"
                onClick={triggerFileInput}>
                <i className="bi-upload"></i>
                <span>Upload new logo</span>
              </Link>
            </div>
            <form className="dentist-office-form small" onSubmit={handleSubmit}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Company Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <label htmlFor="name">Office Name</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="url"
                  className="form-control"
                  id="website"
                  name="website"
                  placeholder="Enter a valid url (http://...)"
                  value={formData.website}
                  onChange={handleChange}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Please enter a valid URL, starting with http:// or https://"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                <label htmlFor="website">Website</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Company Phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <label htmlFor="phone">Company Phone</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Company Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label htmlFor="email">Company Email</label>
              </div>
              <button className="button-pill" type="submit">
                Save Company Info
              </button>
            </form>
          </>
        )}
      </div>

      <div className="card p-4 d-flex flex-col gap-2 mb-2">
        <div className="d-sm-flex flex-row gap-4 mb-2">
          <div className="d-flex flex-column gap-2 flex-grow-1">
            <div className="d-flex justify-content-between align-items-top">
              <h4>Company Description</h4>
              <Link className="d-flex gap-2" onClick={toggleEditingProfile}>
                <i className="bi-pencil"></i>
              </Link>
            </div>
            <p>
              {description ? (
                description
              ) : (
                <span className="fst-italic">
                  Click Edit to add a description of your business.
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDescriptionSection;
