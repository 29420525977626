import { useState } from "react";

const CandidateOnboardingProfileForm = ({ data, onSubmit, loading, skipNumber }) => {
  const [formData, setFormData] = useState({
    name: data?.name || "",
    location: data?.location || "",
    specialties: data?.specialties || "",
    profTitle: data?.profTitle || "",
    yearsExperience: data?.yearsExperience || ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className="card">
      <div className="d-flex justify-content-between align-items-center py-2 px-3">
        <div className="d-flex justify-content-start align-items-center gap-3">
          <i className="bi-person-check" style={{ fontSize: "2rem" }}></i>
          <div>
            <h6 className="mb-0">
              {!skipNumber ? "Verify & Update Your Profile Info" : "Create Your Profile"}
            </h6>
            <span className="small fw-lighter">
              {!skipNumber
                ? "Review, edit, and update your data to create your profile."
                : "Add some basic profile information to get started."}
            </span>
          </div>
        </div>
      </div>
      <form className="py-2 px-3">
        {/* Name */}
        <div className="form-floating mb-3">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
          />
          <label htmlFor="name">Name</label>
        </div>

        {/* Current Location */}
        <div className="form-floating mb-3">
          <input
            type="text"
            id="location"
            name="location"
            placeholder="Current Location"
            className="form-control"
            value={formData.location}
            onChange={handleChange}
          />
          <label htmlFor="location">Current Location</label>
        </div>

        {/* Specialties */}
        <div className="form-floating mb-3">
          <input
            type="text"
            id="specialties"
            name="specialties"
            placeholder="Specialties"
            className="form-control"
            value={formData.specialties}
            onChange={handleChange}
          />
          <label htmlFor="specialties">Specialties</label>
        </div>

        {/* Title */}
        <div className="form-floating mb-3">
          <input
            type="text"
            id="profTitle"
            name="profTitle"
            placeholder="Professional title"
            className="form-control"
            value={formData.profTitle}
            onChange={handleChange}
          />
          <label htmlFor="profTitle">Your Title</label>
        </div>

        {/* Years Practicing */}
        <div className="form-floating mb-3">
          <input
            type="text"
            id="yearsExperience"
            name="yearsExperience"
            placeholder="Years in Practice"
            className="form-control"
            value={formData.yearsExperience}
            onChange={handleChange}
          />
          <label htmlFor="yearsExperiencee">Years in Practice</label>
        </div>
        {!loading ? (
          <button className="button-pill small mb-2" onClick={handleSubmit}>
            Submit
          </button>
        ) : (
          <button
            className="button-pill small d-flex gap-2 align-items-center"
            style={{ whiteSpace: "nowrap" }}
            disabled>
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span role="status">Loading...</span>
          </button>
        )}
      </form>
    </div>
  );
};

export default CandidateOnboardingProfileForm;
