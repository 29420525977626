import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  arrayRemove,
  deleteDoc
} from "firebase/firestore";
import { db } from "../../firebase/config";
import Card from "../Card";
import { Link } from "react-router-dom";

const CompanyLocationsSection = ({ companyId, companyData }) => {
  const [locations, setLocations] = useState([]);
  const [addingLocation, setAddingLocation] = useState(false);
  const [newLocation, setNewLocation] = useState({
    displayName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    email: ""
  });

  useEffect(() => {
    // Fetch existing locations
    const fetchLocations = async () => {
      const locationsRef = collection(db, "companies", companyId, "locations");
      const locationsSnapshot = await getDocs(locationsRef);
      const locationsData = locationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setLocations(locationsData);
    };

    fetchLocations();
  }, [companyId, companyData]);

  const handleAddOrUpdateLocation = async () => {
    try {
      if (newLocation.id) {
        // Update existing location
        const locationDocRef = doc(db, "companies", companyId, "locations", newLocation.id);
        await updateDoc(locationDocRef, {
          displayName: newLocation.displayName,
          address1: newLocation.address1,
          address2: newLocation.address2,
          city: newLocation.city,
          state: newLocation.state,
          zipCode: newLocation.zipCode,
          phone: newLocation.phone,
          email: newLocation.email
        });

        // Update the location in local state
        setLocations((prevLocations) =>
          prevLocations.map((loc) => (loc.id === newLocation.id ? { ...newLocation } : loc))
        );
      } else {
        // Add new location
        const locationsRef = collection(db, "companies", companyId, "locations");
        const newDocRef = await addDoc(locationsRef, newLocation);

        // Add id to the newly created location and update local state
        setLocations([...locations, { ...newLocation, id: newDocRef.id }]);
      }

      // Clear the form and hide it after submission
      setNewLocation({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        phone: "",
        email: ""
      });
      setAddingLocation(false);
    } catch (error) {
      console.error("Error adding or updating location:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLocation((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditLocation = (location) => {
    setNewLocation(location); // Prefill the form with existing location data
    setAddingLocation(true); // Show the form for editing
  };

  const handleDeleteLocation = async (location) => {
    try {
      // Locate and delete location doc tied of chosen location
      const locationDocRef = doc(db, "companies", companyId, "locations", location.id);
      await deleteDoc(locationDocRef, {
        locations: arrayRemove(location) // Remove location from locations array
      });

      //Update local state by filtering out deleted location doc
      setLocations((prev) => prev.filter((loc) => loc.id !== location.id)); // Update local state
    } catch (error) {
      console.error("Error deleting location: ", error);
    }
  };

  return (
    <Card>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h4 className="mb-0">Locations</h4>
        <Link>
          <button
            className={addingLocation ? "d-none" : "button-pill"}
            onClick={() => setAddingLocation(true)}>
            Add Location
          </button>
        </Link>
      </div>
      {addingLocation && (
        <div className="add-location-form mt-4">
          {/* Display Name */}
          <div className="form-floating mb-3">
            <input
              type="text"
              name="displayName"
              className="form-control"
              placeholder="e.g. Detroit or Downtown Houston"
              value={newLocation.displayName}
              onChange={handleChange}
              required
            />
            <label htmlFor="address1">Display Name</label>
          </div>

          {/* Address 1 */}
          <div className="form-floating mb-3">
            <input
              type="text"
              name="address1"
              className="form-control"
              placeholder="Address 1"
              value={newLocation.address1}
              onChange={handleChange}
              required
            />
            <label htmlFor="address1">Address 1</label>
          </div>

          {/* Address 2 */}
          <div className="form-floating mb-3">
            <input
              type="text"
              name="address2"
              className="form-control"
              placeholder="Address 2"
              value={newLocation.address2}
              onChange={handleChange}
            />
            <label htmlFor="address2">Address 2</label>
          </div>

          {/* City, State, and Zip Code (Responsive layout) */}
          <div className="row g-3 mb-3">
            <div className="col-md-6 form-floating">
              <input
                type="text"
                name="city"
                className="form-control"
                placeholder="City"
                value={newLocation.city}
                onChange={handleChange}
                required
              />
              <label htmlFor="city">City</label>
            </div>
            <div className="col-md-3 form-floating">
              <input
                type="text"
                name="state"
                className="form-control"
                placeholder="State"
                value={newLocation.state}
                onChange={handleChange}
                required
              />
              <label htmlFor="state">State</label>
            </div>
            <div className="col-md-3 form-floating">
              <input
                type="text"
                name="zipCode"
                className="form-control"
                placeholder="Zip Code"
                value={newLocation.zipCode}
                onChange={handleChange}
                required
              />
              <label htmlFor="zipCode">Zip Code</label>
            </div>
          </div>

          {/* Phone and Email (Responsive layout) */}
          <div className="row g-3 mb-3">
            <div className="col-md-6 form-floating">
              <input
                type="text"
                name="phone"
                className="form-control"
                placeholder="Phone"
                value={newLocation.phone}
                onChange={handleChange}
              />
              <label htmlFor="phone">Phone</label>
            </div>
            <div className="col-md-6 form-floating">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={newLocation.email}
                onChange={handleChange}
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>

          {/* Buttons */}
          <div className="d-flex align-items-center gap-3 mt-3">
            <button className="button-pill" onClick={handleAddOrUpdateLocation}>
              {newLocation.id ? "Update Location" : "Save Location"}
            </button>
            <Link className="text-decoration-none" onClick={() => setAddingLocation(false)}>
              Cancel
            </Link>
          </div>
        </div>
      )}
      {locations.length > 0 && !addingLocation && (
        <div className="row row-cols-1 row-cols-lg-3 g-4 my-2 small">
          {locations.map((location) => (
            <div key={location.id} className="col mt-0 mb-4">
              <div className="card location-item h-100">
                <div className="card-header background-aqua fw-bold">
                  {location.displayName || location.city}
                </div>
                <div className="card-body">
                  <span>
                    {location.address1}
                    <br />
                    {location.address2 && location.address2}
                  </span>

                  <p>
                    {location.city}, {location.state} {location.zipCode}
                  </p>
                  {location.phone && (
                    <span>
                      <i className="bi-phone"></i> {location.phone}
                    </span>
                  )}
                  {location.email && (
                    <p>
                      <i className="bi-envelope"></i> {location.email}
                    </p>
                  )}
                </div>
                <div className="card-footer">
                  <div className="d-flex justify-content-between">
                    <Link
                      className="text-decoration-none"
                      onClick={() => handleEditLocation(location)}>
                      Edit
                    </Link>
                    <Link
                      className="text-decoration-none"
                      onClick={() => handleDeleteLocation(location)}>
                      Delete
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default CompanyLocationsSection;
