import { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = ({ onClick, resetPasswordEmail, loading }) => {
  const [email, setEmail] = useState("");

  const handleSubmitPasswordResetRequest = (e) => {
    e.preventDefault();
    resetPasswordEmail(email);
  };

  return (
    <>
      <form onSubmit={handleSubmitPasswordResetRequest} className="login-form">
        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="name@example.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <label htmlFor="email" className="public-sans-regular">
            Email address
          </label>
        </div>
        {loading ? (
          <button className="button-large w-100" disabled>
            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
            <span role="status">Loading...</span>
          </button>
        ) : (
          <button className="button-large w-100 public-sans-regular" type="submit">
            Submit Email
          </button>
        )}
        <Link className="small text-decoration-none" onClick={onClick}>
          Back to login
        </Link>
      </form>
    </>
  );
};

export default ForgotPassword;
