import React from "react";

const OnboardingCompanyName = ({ progress }) => {
  return (
    <div className={`card mb-3 ${progress.includes(1) && "theme-dark"}`}>
      <div className="d-flex justify-content-between align-items-center py-2 px-3">
        <div className="d-flex justify-content-start align-items-center gap-3">
          <i className="bi-check-circle-fill" style={{ fontSize: "2rem" }}></i>
          <div>
            <h6 className="mb-0">Add company name</h6>
            <span className="small fw-lighter">Let folks know who you are</span>
          </div>
        </div>
        <span className="">
          <i className="bi-check-lg" />
        </span>
      </div>
    </div>
  );
};

export default OnboardingCompanyName;
