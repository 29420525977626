// Trying to simplify the company jobs view and update the design a bit

import { useState } from "react";
import "./CompanyJobItem.css";

const CompanyJobItem = ({ jobData, handleEditJob, handlePublishJob, toggleDeleteModal }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);

  const toggleMenu = () => {
    setMenuVisible((prev) => !prev);
  };

  const onEditJob = () => {
    handleEditJob(jobData);
  };

  const onPublishJob = () => {
    toggleMenu();
    setStripeLoading(true);
    handlePublishJob(jobData.id);
  };

  const onDeleteJob = () => {
    setMenuVisible(false);
    toggleDeleteModal();
  };

  console.log(jobData.publishDate);

  // Function to convert publishDate to human readable date
  const formattedDate = jobData.publishDate
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      }).format(new Date(jobData.publishDate))
    : "Unknown Date";

  // Function to calculate the date 3 months after publishDate
  const calculateThreeMonthsLater = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 3);
    return newDate;
  };

  const threeMonthsLaterDate = jobData.publishDate
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric"
      }).format(calculateThreeMonthsLater(jobData.publishDate))
    : "Unknown Date";

  if (!jobData) {
    return <div>No job available</div>;
  }
  return (
    <div className="card mb-3 p-2">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="public-sans-bold mb-0">{jobData.jobTitle}</h5>
            <div className="d-flex gap-3 flex-wrap">
              <div className="d-flex gap-1 align-items-center mb-2 mb-sm-0">
                <i className="bi-geo-alt"></i>
                <span>{jobData.location}</span>
              </div>
              {jobData.isFullTime && (
                <div className="d-flex gap-1 align-items-center mb-2 mb-sm-0">
                  <i className="bi-clock"></i>
                  <span>{jobData.isFullTime}</span>
                </div>
              )}
            </div>
            {jobData.publishDate && (
              <div className="mt-2">
                <span>Live Until: {threeMonthsLaterDate}</span>
              </div>
            )}
          </div>
          <div className="position-relative">
            {stripeLoading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading ...</span>
              </div>
            ) : (
              <i
                className={`bi ${!menuVisible ? "bi-three-dots-vertical" : "bi-x-lg"} icon-transition`}
                onClick={toggleMenu}
                style={{ cursor: "pointer" }}></i>
            )}
            <div className={`context-menu ${menuVisible ? "show" : ""}`}>
              <button className="d-flex flex-row align-items-center gap-2" onClick={onEditJob}>
                <i className="bi-pencil"></i>
                <span>Edit</span>
              </button>
              {handlePublishJob && (
                <button className="d-flex flex-row align-items-center gap-2" onClick={onPublishJob}>
                  <i className="bi-upload"></i>
                  <span>Publish</span>
                </button>
              )}
              <button className="d-flex flex-row align-items-center gap-2" onClick={onDeleteJob}>
                <i className="bi-trash"></i>
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyJobItem;
