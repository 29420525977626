import { useState } from "react";

const OnboardingLocation = ({ onboardingProgress, saveLocation, companyData }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [newLocation, setNewLocation] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    email: "",
    displayName: ""
  });

  const isLocationComplete =
    companyData?.onboardingCompleted?.includes(6) || onboardingProgress.includes(6);

  const toggleFormOpen = () => {
    setFormOpen((prevState) => !prevState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLocation((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveLocation(newLocation);
    toggleFormOpen();
  };
  return (
    <div className={`card mb-3 ${isLocationComplete && "theme-dark"}`}>
      <div className="d-flex justify-content-between align-items-center py-2 px-3">
        <div className="d-flex justify-content-start align-items-center gap-3">
          <i className="bi-geo" style={{ fontSize: "2rem" }}></i>
          <div>
            <h6 className="mb-0">Add first location</h6>
            <span className="small fw-lighter">Help everyone find you in person</span>
          </div>
        </div>
        {isLocationComplete && (
          <span className="">
            <i className="bi-check-lg" />
          </span>
        )}
        {!isLocationComplete && !formOpen && (
          <button className="button-pill small" onClick={toggleFormOpen}>
            Add Location
          </button>
        )}
        {!isLocationComplete && formOpen && (
          <i className="bi-x-circle" onClick={toggleFormOpen}></i>
        )}
      </div>
      {formOpen && (
        <>
          <hr className="mt-0" />
          <form onSubmit={handleSubmit} className="small py-2 px-3 mb-2">
            <div className="form-floating mb-3">
              <input
                type="text"
                name="displayName"
                className="form-control"
                placeholder="e.g. Detroit or Downtown Houston"
                value={newLocation.displayName}
                onChange={handleChange}
                required
              />
              <label htmlFor="address1">Display Name</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                name="address1"
                className="form-control"
                placeholder="Address 1"
                value={newLocation.address1}
                onChange={handleChange}
                required
              />
              <label htmlFor="address1">Address 1</label>
            </div>

            {/* Address 2 */}
            <div className="form-floating mb-3">
              <input
                type="text"
                name="address2"
                className="form-control"
                placeholder="Address 2"
                value={newLocation.address2}
                onChange={handleChange}
              />
              <label htmlFor="address2">Address 2</label>
            </div>

            {/* City, State, and Zip Code (Responsive layout) */}
            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="City"
                  value={newLocation.city}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="city">City</label>
              </div>
              <div className="col-md-3 form-floating">
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  placeholder="State"
                  value={newLocation.state}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="state">State</label>
              </div>
              <div className="col-md-3 form-floating">
                <input
                  type="text"
                  name="zipCode"
                  className="form-control"
                  placeholder="Zip Code"
                  value={newLocation.zipCode}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="zipCode">Zip Code</label>
              </div>
            </div>

            {/* Phone and Email (Responsive layout) */}
            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating">
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Phone"
                  value={newLocation.phone}
                  onChange={handleChange}
                />
                <label htmlFor="phone">Phone</label>
              </div>
              <div className="col-md-6 form-floating">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={newLocation.email}
                  onChange={handleChange}
                />
                <label htmlFor="email">Email</label>
              </div>
            </div>
            <button className="button-pill" type="submit">
              Submit
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default OnboardingLocation;
