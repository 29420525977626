// Import necessary modules and components
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCompany } from "../context/CompanyContext";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import AllCandidatesList from "./company/AllCandidatesList";
import SentConnections from "./SentConnections";
import ReceivedConnections from "./ReceivedConnections";
import "./Connections.css";
import { getAllConnections } from "../services/ConnectionService";
import ConnectionsNav from "./ConnectionsNav";
import AcceptedConnections from "./AcceptedConnections";

// This component fetchs all connects for a user and displays them in a tabbed interface
// It uses the ConnectionsContext to fetch the data and the CompanyContext to determine if the user is a Company user
// It can be used by Company and Candidate users to view their connections
const Connections = () => {
  const { companyId, currentUser, hasPublishedJobs, hasActiveSubscription } = useCompany();
  const location = useLocation();
  const navigate = useNavigate();

  const getInitialTab = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("tab") || (companyId ? "all" : "received");
  };

  // State variables to manage loading state and connection requests
  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [acceptedConnections, setAcceptedConnections] = useState([]);
  const [displayActiveLink, setDisplayActiveLink] = useState(getInitialTab);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", displayActiveLink);
    navigate(`?${searchParams.toString()}`, { replace: true });
  }, [displayActiveLink, navigate]);

  const handleLinkClick = (tab) => {
    setDisplayActiveLink(tab);
  };

  // Context hooks to get connection and company data

  const fetchCandidates = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("companyId", "==", null),
        where("profTitle", "!=", null)
      );
      const querySnapshot = await getDocs(q);

      const candidatesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setCandidates(candidatesData);
      console.log(candidatesData);
      //return candidatesData;
    } catch (error) {
      console.error("error fetching candidates:" + error);
      return [];
    }
  };

  const fetchConnections = async () => {
    if (!currentUser) {
      console.error("User is not logged in.");
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const allConnections = await getAllConnections(companyId || currentUser.uid, !!companyId);

      // Set state with fetched connection data
      setSentRequests(allConnections.pendingSent);
      setReceivedRequests(allConnections.pendingReceived);
      setAcceptedConnections(allConnections.accepted);
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to update connection state when a request is accepted or rejected
  const handleConnectionUpdate = (connectionId, newStatus) => {
    setReceivedRequests((prev) => prev.filter((connection) => connection.id !== connectionId));

    if (newStatus === "accepted") {
      // Create a new object to ensure React re-renders correctly
      const acceptedConnection = receivedRequests.find((c) => c.id === connectionId);
      if (acceptedConnection) {
        setAcceptedConnections((prev) => [
          ...prev,
          { ...acceptedConnection, status: "accepted" } // Ensure status is updated
        ]);
      }
    }
  };

  // useEffect hook to fetch connections when component mounts or dependencies change
  useEffect(() => {
    if (companyId) {
      fetchCandidates();
    }
    fetchConnections();
  }, [currentUser, companyId]);

  // Callback function that updates sent requests state when connection request is sent
  const handleNewConnection = (newConnection) => {
    setSentRequests((prev) => [...prev, newConnection]);
  };

  // Render loading state if data is still being fetched
  if (loading) {
    return <div>Loading connections...</div>;
  }

  if (companyId && !hasActiveSubscription) {
    return (
      <section className="connections public-sans-regular">
        <div className="alert alert-warning mt-5">
          You need to have an active subscription to view candidates.
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="connections public-sans-regular">
        <h3 className="public-sans-bold mb-3 d-flex gap-3">
          {!companyId ? (
            <>
              <i className="bi-chat-dots"></i>
              <span>Network</span>
            </>
          ) : (
            <>
              <i className="bi-people"></i>
              <span>Candidates</span>
            </>
          )}
        </h3>

        <div className="card p-4 d-flex flex-col gap-2 mb-2">
          {/* Display warning if company has no published jobs */}
          {companyId && !hasPublishedJobs ? (
            <div className="alert alert-warning">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <span>You need to post a job before you can view candidates.</span>
                <Link to="/company/jobs">Post a job</Link>
              </div>
            </div>
          ) : (
            <>
              {/* Render navigation links */}
              <ConnectionsNav
                companyId={companyId}
                handleLinkClick={handleLinkClick}
                displayActiveLink={displayActiveLink}
              />

              {/* Render content based on active link */}
              {displayActiveLink === "all" && (
                <AllCandidatesList
                  candidates={candidates}
                  hasActiveJobs={hasPublishedJobs}
                  companyId={companyId}
                  onNewConnection={handleNewConnection}
                />
              )}
              {displayActiveLink === "received" && (
                <ReceivedConnections
                  connections={receivedRequests}
                  isCompany={!!companyId}
                  onConnectionUpdate={handleConnectionUpdate}
                />
              )}
              {displayActiveLink === "sent" && (
                <SentConnections connections={sentRequests} isCompany={!!companyId} />
              )}
              {displayActiveLink === "accepted" && (
                <AcceptedConnections connections={acceptedConnections} isCompany={!!companyId} />
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Connections;
