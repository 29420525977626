import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  onSnapshot,
  orderBy,
  limit
} from "firebase/firestore";
import LoadingSpinner from "../LoadingSpinner";
import AddJobForm from "./AddJobForm";
import { db } from "../../firebase/config";
import { useCompany } from "../../context/CompanyContext";
import { initializeStripeCheckout } from "../../services/InitializeStripeCheckout";
import DashedCard from "../DashedCard";
import { Modal } from "../Modal";
import CompanyJobItem from "./CompanyJobItem";

const CompanyJobsList = () => {
  const { currentUser, companyId } = useCompany();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddingJob, setIsAddingJob] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [publishingJobId, setPublishingJobId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const paymentProcessed = useRef(false); // flage to ensure payment is processed only once

  const displayAddJobs = () => setIsAddingJob(true);
  const handleBack = () => {
    setIsAddingJob(false);
    setEditingJob(null);
  };

  // Fetch jobs from Firestore
  const fetchJobs = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, "jobs"), where("companyId", "==", companyId));
      const querySnapshot = await getDocs(q);

      const jobsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      //console.log(jobsData);

      setJobs(jobsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoading(false);
    }
  };

  // Handle Editing Job
  const handleEditJob = (job) => {
    setIsAddingJob(true);
    setEditingJob(job);
  };

  // Delete job modal
  const toggleDeleteModal = () => {
    setDeleteModal((prevState) => !prevState);
  };
  // Delete job
  const deleteJob = async (jobId) => {
    try {
      await deleteDoc(doc(db, "jobs", jobId));
      setJobs(jobs.filter((job) => job.id !== jobId));
      console.log("Job deleted successfully");
    } catch (error) {
      console.error("Error deleting job: ", error);
    }
  };

  // Handle job publishing and Stripe payment flow
  const handlePublishJob = async (jobId) => {
    const priceId = "price_1QBNoqITdvdrxo1nbuVtKAT3";
    setPublishingJobId(jobId);
    try {
      // Step 1: Launch Stripe payment flow
      const result = await initializeStripeCheckout(currentUser, priceId, jobId);
      console.log(`Stripe checkout session initiated: ${result.docRef.id}`);
      if (result.success) {
        // Save sessionId to job document (temporarily storing for payment verification)
        const jobDocRef = doc(db, "jobs", jobId);
        await updateDoc(jobDocRef, {
          stripeSessionId: result.docRef.id
        });

        setTimeout(() => {
          setPublishingJobId(null);
          navigate("/company/jobs");
        }, 5000);
      } else {
        setPublishingJobId(null);
        alert("An error occurred. Payment could not be initiated.");
      }
    } catch (error) {
      console.error("Error during the Stripe payment process:", error);
      setPublishingJobId(null);
      alert("An error occurred while processing the payment.");
    }
  };

  // Handle payment status based on query params
  const handlePaymentStatus = async () => {
    const params = new URLSearchParams(location.search);
    const paymentStatus = params.get("payment_status");
    const sessionId = params.get("session_id");
    const jobId = params.get("jobId");

    if (paymentStatus === "success" && sessionId && !paymentProcessed.current) {
      paymentProcessed.current = true; // set the flag to prevent multiple executions
      try {
        // Listen for new payments being added to the 'payments' collection
        const q = query(
          collection(db, "users", currentUser.uid, "payments"),
          where("status", "==", "succeeded"), // Listen for succeeded payments
          orderBy("created", "desc"), // order by creationg time to get the most recent first
          limit(1) // limit to the most recent payment
        );

        const unsubscribe = onSnapshot(q, async (snapshot) => {
          if (!snapshot.empty) {
            const paymentDoc = snapshot.docs[0];
            const paymentData = paymentDoc.data();
            const paymentIntentId = paymentData.id;

            // values to add to the job document
            const publishDate = new Date().toISOString();
            const expirationDate = new Date(publishDate);
            expirationDate.setMonth(expirationDate.getMonth() + 3);
            const expirationDateISO = expirationDate.toISOString();

            // update the job document with the payment details
            const jobDocRef = doc(db, "jobs", jobId);
            await updateDoc(jobDocRef, {
              isPublished: true,
              publishDate: publishDate,
              expirationDate: expirationDateISO,
              renewalNumber: 0,
              stripeTransactionId: paymentIntentId
            });

            // alert("Payment successful! Your job has been published.");
            unsubscribe(); // Stop listening once the job has been updated
            navigate(`/company/jobs?jobPublished=success`, { replace: true });
          } else {
            console.error("payment not found.");
          }
        });
      } catch (error) {
        console.error("Error processing the payment:", error);
        alert("An error occurred while processing the payment.");
        navigate("/company/jobs", { replace: true });
      }
    } else if (paymentStatus === "cancel" && !paymentProcessed) {
      paymentProcessed.current = true; // set the flag to prevent multiple executions
      alert("Payment was canceled. Your job was not published.");
      navigate("/company/jobs", { replace: true });
    }
  };

  // Use URL Params to display custom success modal
  const displaySuccessModal = () => {
    const params = new URLSearchParams(location.search);
    const jobPublished = params.get("jobPublished");

    if (jobPublished === "success") {
      setSuccessModal(true);
    }
  };

  useEffect(() => {
    fetchJobs();
    handlePaymentStatus();
    displaySuccessModal();
  }, [companyId, location.search]);

  const draftJobs = jobs.filter((job) => !job.isPublished);
  const publishedJobs = jobs.filter((job) => job.isPublished);
  console.log(publishedJobs);

  const toggleModal = () => {
    setModalVisible((prevState) => !prevState);
  };

  const closeSuccessModal = () => {
    setSuccessModal(false);
    navigate("/company/jobs", { replace: true });
  };

  return (
    <section id="jobs" className="jobs public-sans-regular">
      {/* Page is loading */}
      {loading && <LoadingSpinner />}

      {deleteModal && (
        <Modal title="Delete Job?" onClose={toggleDeleteModal}>
          <p>Are you sure you want to delete this job?</p>
          <div className="d-flex justify-content-end align-items-center gap-3">
            <button className="btn btn-danger">Yes</button>
            <button className="btn btn-secondary" onClick={toggleDeleteModal}>
              No
            </button>
          </div>
        </Modal>
      )}

      {successModal && (
        <Modal title="Job Published Successfully!" onClose={closeSuccessModal}>
          <p>Your job was successfully published! Now, go find some candidates!</p>
        </Modal>
      )}

      {modalVisible && (
        <Modal title="About Draft Jobs" onClose={toggleModal}>
          <p>
            Draft jobs have not been posted to the network yet. Edit them until they're ready, then
            click Publish when you're ready to advertise the job to available dentists.
          </p>
        </Modal>
      )}

      {!loading && (
        <div className="jobs-list">
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/* Header with button */}
            <h3 className="public-sans-bold">
              {!isAddingJob ? "Current Jobs" : editingJob ? "Update Job" : "Add New Job"}
            </h3>
            <button className="button-pill" onClick={isAddingJob ? handleBack : displayAddJobs}>
              <i className={isAddingJob ? "bi-arrow-left-short" : "bi-plus"}></i>
              <span>{isAddingJob ? "Go Back" : "Add Job"}</span>
            </button>
          </div>

          {/* if not adding or editing a job, show list of jobs */}
          {!isAddingJob && (
            <>
              {draftJobs.length == 0 && publishedJobs.length == 0 && (
                <DashedCard
                  title="Post your first job"
                  body="Draft all the essential elements of your first job, and only pay when you're ready to publish. Just click 'Add Job' above."
                />
              )}

              {draftJobs.length > 0 && (
                <div className="card p-4 d-flex flex-col gap-2 mb-2">
                  <div className="mb-2 d-flex align-items-top gap-2">
                    <h4 className="">Draft Jobs</h4>
                    <i className="bi-info-circle" onClick={toggleModal}></i>
                  </div>

                  {draftJobs.map((job) => (
                    <CompanyJobItem
                      key={job.id}
                      jobData={job}
                      handleEditJob={handleEditJob}
                      handlePublishJob={handlePublishJob}
                      toggleDeleteModal={toggleDeleteModal}
                    />
                  ))}
                </div>
              )}

              {publishedJobs.length > 0 && (
                <>
                  <div className="card p-4 d-flex flex-col gap-2 mb-2">
                    <div className="mb-2 d-flex align-items-top gap-2">
                      <h4 className="">Published Jobs</h4>
                    </div>

                    {publishedJobs.map((job) => (
                      <CompanyJobItem
                        key={job.id}
                        jobData={job}
                        handleEditJob={handleEditJob}
                        toggleDeleteModal={toggleDeleteModal}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
          {/* if is adding or editing job, then show job form */}
          {isAddingJob && (
            <div className="add-job-form">
              <AddJobForm
                companyId={companyId}
                handleBack={handleBack}
                fetchJobs={fetchJobs}
                editingJob={editingJob}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default CompanyJobsList;
