import DOMPurify from "dompurify";

const FormattedText = ({ htmlString }) => {
  // Sanitize the HTML string
  const sanitizedHTML = DOMPurify.sanitize(htmlString);

  return (
    <div
      className="formatted-text-viewer"
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
  );
};

export default FormattedText;
