import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { getJobStatus } from "../utils/jobStatus";

import LoadingSpinner from "../components/LoadingSpinner";
import Modal from "../components/Modal";
import CompanyNavbar from "../components/CompanyNavbar";

const PublicCandidateProfile = () => {
  const { id } = useParams();
  const [candidateData, setCandidateData] = useState(null);
  const [candidateStatus, setCandidateStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const candidateDocRef = doc(db, "users", id);
        const candidateDoc = await getDoc(candidateDocRef);

        if (candidateDoc.exists()) {
          const data = candidateDoc.data();
          console.log(`Candidate is: `, data);
          setCandidateData(data);
          setCandidateStatus(getJobStatus(data.status));
        } else {
          console.error("candidate not found");
        }
      } catch (error) {
        console.error("Eerror fetching canddiate data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCandidateData();
  }, [id]);

  if (loading) {
    return <div>Loading candidate data...</div>;
  }

  if (!candidateData) {
    return <div>Candidate not found...</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  const submitContactForm = () => {
    console.log("Message submitted with message: " + message);
  };

  return (
    <>
      {showModal && (
        <Modal title="Contact Candidate" onClose={onModalClose}>
          <form onSubmit={submitContactForm}>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="example@email.com"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <label htmlFor="email">Email Address</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="254-312-1465"
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
              />
              <label htmlFor="phone">Callback Number</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                placeholder="Write your message here."
                id="message"
                style={{ height: 200 }}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              />
              <label htmlFor="message">Message</label>
            </div>
            <button className="button-pill" typeof="submit">
              Submit
            </button>
          </form>
        </Modal>
      )}
      <section className="candidate-listing public-sans-regular">
        <button className="button-pill mb-4" onClick={handleBack}>
          Go Back
        </button>
        {loading && <LoadingSpinner />}
        <div className="card p-4 d-flex flex-col gap-2 mb-2">
          <h3>
            {candidateData.firstName}, {candidateData.profTitle}
          </h3>
          <div className="d-flex gap-3">
            {candidateData.location && (
              <div className="d-flex gap-1">
                <i className="bi-geo-alt"></i>
                <span>{candidateData.location}</span>
              </div>
            )}
            {candidateData.status && (
              <div className="d-flex gap-1">
                <i className="bi-flag"></i>
                <span>{candidateStatus}</span>
              </div>
            )}
          </div>
          <hr />
          <div id="summary" className="d-flex flex-column gap-1 mb-4">
            <h5 className="fw-bolder">Summary</h5>
            {candidateData.description && <p>{candidateData.description}</p>}
            {candidateData.locationPrefs && (
              <>
                <span className="fw-bold">Preferred Locations:</span>
                <div className="d-flex gap-3">
                  {candidateData.locationPrefs.map((pref, index) => (
                    <span key={index}>{pref}</span>
                  ))}
                </div>
              </>
            )}
          </div>
          {candidateData.experience && (
            <div id="experience" className="d-flex flex-column gap-1">
              <h5 className="fw-bolder">Experience</h5>
              <div>
                <span className="fw-bolder">Years of Experience:</span>{" "}
                {candidateData.experience.years
                  ? candidateData.experience.years
                  : candidateData.yearsExperience}
              </div>
              {candidateData.experience.licenses && (
                <div>
                  <span className="fw-bolder">Licenses:</span> {candidateData.experience.licenses}
                </div>
              )}
              {candidateData.experience.procedures && (
                <>
                  <div>
                    <span className="fw-bolder">Procedures Performed:</span>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: candidateData.experience.procedures }}></div>
                </>
              )}
            </div>
          )}

          <div className="d-flex flex-column gap-1" id="eduction">
            {candidateData.education && (
              <>
                <h5 className="fw-bolder">Education</h5>
                {candidateData.education.map((edu, index) => (
                  <div key={index} className="d-flex flex-column mb-2">
                    <span className="fw-bolder">
                      {edu.institution}, {edu.startYear} - {edu.endYear}
                    </span>
                    <span>
                      {edu.startYear} - {edu.endYear}
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: edu.additionalContent }}></span>
                  </div>
                ))}
              </>
            )}
          </div>

          {/* <p>{candidateData.createdAt}</p>
          <p>{id}</p> */}
          {/*}
          <button className="button-pill" onClick={handleShowModal}>
            Invite Candidate to Apply
          </button>
          {*/}
          <Link
            className="button-pill"
            to={`mailto:${candidateData.email}?subject=You're invited to apply to our open position.`}>
            <div className="d-flex gap-2 align-items-center justify-content-center">
              <span>Invite to Apply</span>
              <i className="bi-box-arrow-up-right"></i>
            </div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default PublicCandidateProfile;
