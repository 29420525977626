import { useState } from "react";
import Card from "../Card";
import { Link } from "react-router-dom";

const AboutCompanySection = ({ aboutData, handleAddSection }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const [aboutEntry, setAboutEntry] = useState({
    sectionTitle: "",
    sectionBody: ""
  });

  const toggleDisplayForm = () => {
    setDisplayForm((prevDisplayForm) => !prevDisplayForm);
  };

  const handleFormSubmission = async (e) => {
    // setLoading(true);
    e.preventDefault();
    const newSection = {
      sectionTitle: aboutEntry.sectionTitle,
      sectionBody: aboutEntry.sectionBody,
      createdAt: new Date()
    };
    handleAddSection(newSection);
    // setLoading(false);
    setAboutEntry({ sectionTitle: "", sectionBody: "" });
    setDisplayForm(false);
  };

  return (
    <Card>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h4 className="mb-0">About Our Company</h4>
        <Link onClick={toggleDisplayForm}>
          <button className={!displayForm ? "button-pill" : "d-none"}>Add a Section</button>
        </Link>
      </div>
      {displayForm && (
        <div className="my-3">
          <form onSubmit={handleFormSubmission}>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="sectionTitle"
                name="sectionTitle"
                placeholder="Section Title"
                value={aboutEntry.sectionTitle}
                onChange={(e) =>
                  setAboutEntry({
                    ...aboutEntry,
                    sectionTitle: e.target.value
                  })
                }
                required
              />
              <label htmlFor="sectionTitle">Section Title</label>
            </div>
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                id="sectionBody"
                name="sectionBody"
                style={{ height: "100px" }}
                placeholder="Section Body"
                value={aboutEntry.sectionBody}
                onChange={(e) =>
                  setAboutEntry({
                    ...aboutEntry,
                    sectionBody: e.target.value
                  })
                }
              ></textarea>
              <label htmlFor="sectionBody">Section Body</label>
            </div>
            <button type="submit" className="button-pill me-3">
              Save Section
            </button>
            <Link onClick={toggleDisplayForm} className="text-decoration-none">
              Cancel
            </Link>
          </form>
        </div>
      )}
      {!aboutData && !displayForm && (
        <div>Add custom sections that tell candidates more about your practice.</div>
      )}
      {aboutData &&
        aboutData.map((section, index) => (
          <div key={index} className="mb-3">
            <hr />
            <h5>{section.sectionTitle}</h5>
            <p>{section.sectionBody}</p>
          </div>
        ))}
    </Card>
  );
};

export default AboutCompanySection;
