export const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case "auth/invalid-credential":
      return "Incorrect email or password. Please try again or click Forgot Password.";
    case "auth/password-does-not-meet-requirements":
      return "Please choose a more secure password. Your password must contain an upper case character, a lower case character, a numeric character, a non-alphanumeric character.";
    case "auth/email-already-in-use":
      return "This email is already in use. Please try logging in instead.";
    case "auth/invalid-email":
      return "Please enter a valid email address.";
    case "auth/weak-password":
      return "Your password is too weak. Please use a stronger password.";
    case "auth/operation-not-allowed":
      return "This operation is not allowed. Please contact support.";
    default:
      return "An error occurred. Please try again later.";
  }
};
