import { Link } from "react-router-dom";
import logo from "/Medis-Teal-Logo.png";
import "./LoggedOutMenu.css";

const LoggedOutMenu = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light logged-out-nav pt-sm-5">
      <div className="container">
        {/* left-aligned logo for mobile only */}
        <Link to="/" className="navbar-brand d-lg-none">
          <img
            src={logo}
            alt="medis-logo-mobile-display"
            className="img-fluid"
            style={{ height: "40px" }}
          />
        </Link>

        {/* Navbar toggler for mobile */}
        <button
          className="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Collapsible Menu */}
        <div className="collapse navbar-collapse" id="navbarMenu">
          {/* Left-aligned menu items */}
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/create-account?type=individual" className="nav-link me-2">
                Dentists
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/create-account?type=company" className="nav-link me-2">
                Practices
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link me-2" to="/testimonials">
                Testimonials
              </Link>
            </li> */}
          </ul>

          {/* Center-aligned logo for larger screens */}
          <Link className="navbar-brand mx-auto d-none d-lg-block text-center position-absolute start-50 translate-middle-x">
            <img
              src={logo}
              alt="Medis Logo for Desktop"
              className="img-fluid"
              style={{ height: "50px" }}
            />
          </Link>

          {/* Rigth-Aligned Buttons */}
          <div className="d-flex ms-auto align-items-center">
            <Link className="nav-link mx-2" to="/login">
              Login
            </Link>
            <Link className="button-pill mx-2" to="create-account">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default LoggedOutMenu;
