import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "/Medis-Teal-Logo.png";
import "./Navbar.css";
import { useAuth } from "../context/AuthContext";

const Navbar = ({ onMenuClick, companyId }) => {
  const { currentUser, logout } = useAuth();

  if (currentUser) {
    return (
      <nav className="navbar bg-white border-bottom public-sans-regular small fixed-top">
        <div className="navbar-container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="medis-logo" height="32" />
          </Link>
          <div className="d-flex flex-row align-items-center">
            <ul className="d-flex flex-row align-items-center list-unstyled gap-2 mb-0">
              <li className="nav-item text-center">
                <NavLink
                  to="/candidate/jobs"
                  className={({ isActive }) => `text-decoration-none ${isActive ? "active" : ""}`}>
                  <i className="bi-briefcase d-block"></i>
                  <span className="d-block">Jobs</span>
                </NavLink>
              </li>
              <li className="nav-item text-center">
                <NavLink
                  to="/candidate/profile"
                  className={({ isActive }) => `text-decoration-none ${isActive ? "active" : ""}`}>
                  <i className="bi-gear d-block"></i>
                  <span className="d-block">Profile</span>
                </NavLink>
              </li>
              <li className="nav-item text-center">
                <NavLink
                  to="/candidate/network"
                  className={({ isActive }) => `text-decoration-none ${isActive ? "active" : ""}`}>
                  <i className="bi-chat-dots d-block"></i>
                  <span className="d-block">Network</span>
                </NavLink>
              </li>
              <Link onClick={logout} className="text-decoration-none logout">
                <li className="nav-item text-center">
                  <i className="bi-power d-block"></i>
                  <span className="d-block">Sign Out</span>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="navbar bg-white border-bottom public-sans-regular small">
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="medis-logo" height="32" />
        </Link>
        <div className="d-flex flex-row align-items-center gap-2">
          <Link to="/login">
            <button className="button-pill">Log In</button>
          </Link>
          <Link to="/create-account">
            <button className="button-pill">Create Account</button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
