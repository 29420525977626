import ConnectionItem from "./ConnectionItem";
import "./Connections.css";

const SentConnections = ({ connections, isCompany }) => {
  if (!connections.length) {
    return <p>No requests sent by you.</p>;
  }

  console.log("Sent connections:", connections);

  return (
    <>
      {connections.map((connection) => {
        const formattedDate = connection.createdAt
          ? new Date(connection.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            })
          : "Unknown Date";
        return (
          <ConnectionItem
            key={connection.id}
            companyName={connection.companyName}
            date={formattedDate}
            status={connection.status}
            companyId={connection.companyId}
            profTitle={connection.profTitle}
            name={connection.name}
          />
        );
      })}
    </>
  );
};

export default SentConnections;
