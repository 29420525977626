import { useState, useEffect } from "react";
import { useConnections } from "../context/ConnectionsContext";
import { useAuth } from "../context/AuthContext";
import ConnectionItem from "./ConnectionItem";

const ReceivedConnections = ({ connections, isCompany, onConnectionUpdate }) => {
  const { updateConnectionStatus } = useConnections();
  const { currentUser } = useAuth();

  const handleStatusChange = async (connectionId, newStatus) => {
    try {
      // Update the connection status in Firestore
      await updateConnectionStatus(connectionId, newStatus, currentUser.uid);

      // Update the UI by calling the callback to refresh connections
      onConnectionUpdate(connectionId, newStatus);
    } catch (error) {
      console.error("Error updating connection:", error);
      alert("Failed to update the connection. Please try again.");
    }
  };

  return (
    <>
      {!connections.length && <p>No pending requests.</p>}
      {connections.map((connection) => {
        const formattedDate = connection.createdAt
          ? new Date(connection.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            })
          : "Unknown Date";

        return (
          <ConnectionItem
            key={connection.id}
            connectionId={connection.id}
            date={formattedDate}
            status={connection.status}
            profTitle={connection.profTitle}
            name={connection.name}
            firstName={connection.firstName} //Legacy db elements, can be phased out
            isReceived
            onStatusChange={handleStatusChange}
            candidateId={connection.candidateId}
            companyId={connection.companyId}
            initiator={connection.initiator}
            companyName={connection.companyName}
          />
        );
      })}
    </>
  );
};

export default ReceivedConnections;
