import { createContext, useState, useContext, useCallback } from "react";
import { collection, query, where, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase/config";
import * as ConnectionService from "../services/ConnectionService";

const ConnectionsContext = createContext();

export const ConnectionsProvider = ({ children }) => {
  const [connectionsCache, setConnectionsCache] = useState({});

  // Fetch one connection by company and candidate ids
  const getConnection = useCallback(
    async (candidateId, companyId) => {
      const cacheKey = `${candidateId}-${companyId}`;
      if (connectionsCache[cacheKey]) {
        return connectionsCache[cacheKey];
      }

      try {
        // Uses fetchConnection function from ConnectionService to get the connection
        const connection = await ConnectionService.fetchConnection(candidateId, companyId);
        if (connection) {
          setConnectionsCache((prev) => ({ ...prev, [cacheKey]: connection }));
        }
        return connection;
      } catch (error) {
        console.error("Error fetching connection:", error);
        return null;
      }
    },
    [connectionsCache]
  );

  // Fetch all connections for a given candidateId
  const getAllConnectionsForCandidate = useCallback(async (candidateId) => {
    try {
      const connectionsRef = collection(db, "connections");
      const connectionsQuery = query(connectionsRef, where("candidateId", "==", candidateId));
      const connectionSnapshot = await getDocs(connectionsQuery);

      const connections = connectionSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      // Separate connections into different lists
      const pendingReceived = connections.filter(
        (connection) => connection.status === "pending" && connection.initiator === "company"
      );
      const pendingSent = connections.filter(
        (connection) => connection.status === "pending" && connection.initiator === "candidate"
      );

      const accepted = connections.filter((connection) => connection.status === "accepted");

      return {
        pendingReceived,
        pendingSent,
        accepted
      };
    } catch (error) {
      console.error("Error fetching connections for candidate:", error);
      throw error;
    }
  }, []);

  // Send a connection requestion from candidate to company
  const sendConnectionRequest = useCallback(async (candidateId, companyId) => {
    try {
      const connection = await ConnectionService.createConnection(candidateId, companyId);

      // Update the context cache
      setConnectionsCache((prev) => ({
        ...prev,
        [`${candidateId}-${companyId}`]: connection
      }));
    } catch (error) {
      console.error("Error creating connection:", error);
    }
  }, []);

  // Get all the connections for one company
  const getAllConnectionsForCompany = async (companyId) => {
    try {
      const connectionsRef = collection(db, "connections");
      const connectionQuery = query(connectionsRef, where("companyId", "==", companyId));
      const connectionSnapshot = await getDocs(connectionQuery);

      const requestedByUser = [];
      const requestedByOthers = [];

      connectionSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.initiator === "company") {
          requestedByUser.push({ id: doc.id, ...data });
        } else {
          requestedByOthers.push({ id: doc.id, ...data });
        }
      });

      return { requestedByUser, requestedByOthers };
    } catch (error) {
      console.error("Error fetching company connections:", error);
      throw error;
    }
  };

  // Update a connection status
  const updateConnectionStatus = async (connectionId, newStatus, userId) => {
    try {
      const connectionRef = doc(db, "connections", connectionId);
      await updateDoc(connectionRef, {
        status: newStatus,
        updatedAt: new Date().toISOString(),
        updatedBy: userId
      });
      return { success: true };
    } catch (error) {
      console.error("Error updating connection status:", error);
      throw error;
    }
  };

  return (
    <ConnectionsContext.Provider
      value={{
        getConnection,
        getAllConnectionsForCandidate,
        sendConnectionRequest,
        getAllConnectionsForCompany,
        updateConnectionStatus
      }}>
      {children}
    </ConnectionsContext.Provider>
  );
};

export const useConnections = () => useContext(ConnectionsContext);
