import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import AddExperienceForm from "./AddExperienceForm";

const CandidateExperience = ({ experience, saveExperience, yearsExperience }) => {
  // State that controls display of the experience form
  const [isEditing, setIsEditing] = useState(false);

  const toggleEditingExperience = () => {
    setIsEditing((prevState) => !prevState);
  };

  const addExperience = async (experienceData) => {
    saveExperience(experienceData);
  };

  return (
    <div className="card p-4 d-flex flex-col gap-2 mb-2">
      <div className="d-flex justify-content-between align-items-center">
        <h4>{!isEditing ? "Experience" : "Add Your Experience"}</h4>
        <Link onClick={toggleEditingExperience} className="d-flex gap-2">
          <i className="bi-pencil-square"></i>
          Edit
        </Link>
      </div>
      {!isEditing && experience && (
        <div>
          <div className="mb-3">
            <h6>Years of Experience</h6>
            <span>{experience.years}</span>
          </div>

          <div className="mb-3">
            <h6>Current Licenses</h6>
            <span>{experience.licenses}</span>
          </div>
          <div className="mb-3">
            <h6>Procedures Performed</h6>
            <span dangerouslySetInnerHTML={{ __html: experience.procedures }} />
          </div>
        </div>
      )}
      {!isEditing && !experience && yearsExperience && (
        <div className="mb-3">
          <h6>Years of Experience</h6>
          <span>{yearsExperience}</span>
        </div>
      )}

      {!isEditing && !experience && !yearsExperience && (
        <p>Click Edit to add your information about your experience</p>
      )}

      {isEditing && (
        <AddExperienceForm
          toggleEditingExperience={toggleEditingExperience}
          addExperience={addExperience}
          currentExperience={experience}
          yearsExperience={yearsExperience}
        />
      )}
    </div>
  );
};

export default CandidateExperience;
