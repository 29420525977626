import { Link, NavLink } from "react-router-dom";
import logo from "/Medis-Teal-Logo.png";
import { useAuth } from "../context/AuthContext";

const CompanyNavbar = ({ isOnboardingComplete }) => {
  const { logout } = useAuth();

  return (
    <nav className="navbar bg-white border-bottom public-sans-regular small fixed-top">
      <div className="navbar-container">
        <div className="d-flex flex-row align-items-center">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="medis-logo" height="32" />
          </Link>
        </div>
        <div className="d-flex flex-row align-items-center">
          <ul className="d-flex flex-row align-items-center list-unstyled gap-2 mb-0">
            {isOnboardingComplete && (
              <>
                <li className="nav-item text-center">
                  <NavLink
                    to="/company/jobs"
                    className={({ isActive }) =>
                      `text-decoration-none ${isActive ? "active" : ""}`
                    }>
                    {({ isActive }) => (
                      <>
                        <i
                          className={`${isActive ? "bi-briefcase-fill" : "bi-briefcase"} d-block`}></i>
                        <span className="d-none d-sm-block">Jobs</span>
                      </>
                    )}
                  </NavLink>
                </li>
                {/* <li className="nav-item text-center">
                  <NavLink
                    to="/company/candidates"
                    className={({ isActive }) =>
                      `text-decoration-none ${isActive ? "active" : ""}`
                    }>
                    {({ isActive }) => (
                      <>
                        <i className={`${isActive ? "bi-people-fill" : "bi-people"} d-block`}></i>
                        <span className="d-none d-sm-block">Candidates</span>
                      </>
                    )}
                  </NavLink>
                </li> */}
                <li className="nav-item text-center">
                  <NavLink
                    to="/company/network"
                    className={({ isActive }) =>
                      `text-decoration-none ${isActive ? "active" : ""}`
                    }>
                    {({ isActive }) => (
                      <>
                        <i
                          className={`${isActive ? "bi-chat-dots-fill" : "bi-chat-dots"} d-block`}></i>
                        <span className="d-none d-sm-block">Network</span>
                      </>
                    )}
                  </NavLink>
                </li>
              </>
            )}
            <li className="nav-item text-center">
              <NavLink
                to="/company/profile"
                className={({ isActive }) => `text-decoration-none ${isActive ? "active" : ""}`}>
                {({ isActive }) => (
                  <>
                    <i className={`${isActive ? "bi-gear-fill" : "bi-gear"} d-block`}></i>
                    <span className="d-none d-sm-block">Profile</span>
                  </>
                )}
              </NavLink>
            </li>
            <Link onClick={logout} className="text-decoration-none logout">
              <li className="nav-item text-center">
                <i className="bi-power d-block"></i>
                <span className="d-none d-sm-block">Sign Out</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default CompanyNavbar;
