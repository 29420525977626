import React from "react";
import "./DashedCard.css";

const DashedCard = ({ title, body }) => {
  return (
    <div className="card border-box py-3 px-2">
      <div className="card-body">
        <h4 className="card-title">{title}</h4>
        <div className="card-text">{body}</div>
      </div>
    </div>
  );
};

export default DashedCard;
