import profilePic from "/user-pic.png";
import { Link } from "react-router-dom";
import { getJobStatus } from "../../utils/jobStatus";
import "./CandidateProfile.css";

const CandidateProfileInfoSection = ({
  name,
  title,
  location,
  email,
  status,
  description,
  photo,
  handleFormState
}) => {
  let userStatus = getJobStatus(status);
  return (
    <div className="card p-4 d-flex flex-col gap-2 mb-2">
      <div className="d-flex flex-row gap-4 mb-2">
        <img className="profile-user-photo" src={photo ? photo : profilePic} alt="user photo" />{" "}
        {/* show photo from db if it exists */}
        <div className="d-flex flex-column gap-2 flex-grow-1">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">{name}</h4>
            <Link onClick={handleFormState} className="d-flex gap-2">
              <i className="bi-pencil-square"></i>
              Edit
            </Link>
          </div>
          <span className="small text-uppercase">{title}</span>
          <hr className="w-75 mt-1" />
          <div className="d-flex gap-3 flex-wrap">
            {location && (
              <div className="d-flex gap-2">
                <i className="bi-geo-alt"></i>
                {location}
              </div>
            )}
            {status && (
              <div className="d-flex gap-2">
                <i className="bi-flag"></i>
                {userStatus}
              </div>
            )}
            {email && (
              <div className="d-flex gap-2">
                <i className="bi-envelope"></i>
                {email}
              </div>
            )}
          </div>
          <div className="mt-3">
            {!description ? "Click edit to write an employer-friendly description." : description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateProfileInfoSection;
