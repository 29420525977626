import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import "./CandidateHome.css";
import LoadingSpinner from "../LoadingSpinner";
import CandidateJobsList from "./CandidateJobsList";
import CandidateProfile from "./CandidateProfile";

const CandidateHome = ({ currentUser, userId }) => {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeComponent, setActiveComponent] = useState("jobs");
  const handleMenuClick = (component) => {
    setActiveComponent(component);
  };

  const navigate = useNavigate();

  // User userId to get user info

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (!userId) {
          throw new Error("cannot find a user");
        }

        // get user info with the userId
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          throw new Error("No user exists with this id");
        }

        // return user data
        const data = userDoc.data();
        setProfileData(data);
        if (!data.location) {
          navigate("/onboarding");
        }
      } catch (error) {
        console.error("error fetching company information:", error.message);
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, [userId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Navbar onMenuClick={handleMenuClick} />
      <div className="candidate-container">
        {activeComponent === "jobs" && <CandidateJobsList />}
        {activeComponent === "profile" && <CandidateProfile />}
      </div>
    </>
  );
};

export default CandidateHome;
